import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { connect } from 'react-redux';
import { goBack,push } from 'react-router-redux';
import _ from "lodash";
import { NavLink } from "react-router-dom";

import logo from "assets/img/logo.png";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";



class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-black"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  goToUrl = url => {
		window.open(url, '_self');
  };

  getImage = (imagenes) =>{
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
  }

  handlerGoTo=(go) =>{

    this.props.goTo(go);

  }

  userNavBar=()=>{
    const { user, usuario} = this.props;
    const { avatar: avatarUsuario } = usuario;
    const { avatar: avatarUser} = user;

    const avatarToShow = usuario && user._id === usuario._id ? avatarUsuario ? avatarUsuario : 'defaultPerfil.png' : avatarUser ? avatarUser : 'defaultPerfil.png' ;
    const images = _.compact(avatarToShow !== '' ? _.split(avatarToShow, ',') : []);

    try{
    if(images[0] ==='undefined'){
      images[0] =images[1];
    }}catch(err){console.log(err)}
    return(
      <div className={classnames('navbar-absolute')}>
      <div>
        
      </div>
      <div style={{width:'100%', color:"#FFF", float:'right'}}>
          <div className="photo" style={{width:"40px", height:"40px", float:"right", margin:'10px'}}>
              <img alt="..."  style={{ borderRadius:'2rem'}} src={ !images ?  "https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png":"https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/" + images[0]}/>
          </div>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '10px', border:'none', float:"right"}}
              onClick={e => {
                this.handlerGoTo(`/auth/usuario/${this.props.user._id}`);
              }}
            >
              <h6><i className="tim-icons icon-single-02" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Mi Perfil </h6>
            </Button>
            {/* <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '10px', border:'none', float:"right"}}
              onClick={e => {
                this.handlerGoTo(`/auth/mis-compras/${this.props.user._id}`);
              }}
            >
              <h6><i className="icon-cart-arrow-down" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Mis Compras </h6>
            </Button> */}
            { user.type === 'admin' &&
          <div style={{marginTop:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '10px', border:'none', float:"right"}}
              onClick={e => {
                this.handlerGoTo('/auth/administracion/');
              }}
            >
              <h6><i className="icon-line-cog" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Administración </h6>
            </Button>
          </div>
          }
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '10px', border:'none', float:"right"}}
              onClick={e => {
                console.log("borrar localstorage y regargar");
                localStorage.clear();
                this.goToUrl('/');
              }}
            >
              <h6><i className="icon-signout" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Salir </h6>
            </Button>
				</div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%',}}>
                <img alt="logo" src={logo} width="500px" height="150px"/>
            </div>
<Navbar className={classnames( this.state.color)} expand="lg">
  <Container fluid style={{ marginTop: '0px' }}>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
           <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>

          <Collapse isOpen={this.state.collapseOpen} onClick={this.state.collapseOpen ? this.toggleCollapse : undefined } navbar>
            <Nav navbar style={{marginLeft:'auto' , marginRight:'auto'}}>
            <NavItem>
                <NavLink to="/auth/home" className="nav-link" style={{color:'#FFF',textAlign:'center'}}>
                  Home
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink to="/auth/sobre-nosotros" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Sobre nosotros
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/servicios" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Eventos
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/auth/galeria" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Galería de trabajos
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink to="/auth/agenda" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                 Agenda de Eventos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/contacto" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Contacto
                </NavLink>
              </NavItem>
                {/* <NavItem>
                <NavLink to="/auth/quien-soy" className="nav-link" style={{color:'#717171', textAlign:'center'}}>
                  <i className="icon-address-card" style={{color:'#717171'}}/><br/> Quien soy?
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/turnos-presenciales" className="nav-link" style={{color:'#717171', textAlign:'center'}}>
                  <i className="icon-calendar-alt" style={{color:'#717171'}}/><br/> Turnos presenciales
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/planes-a-distancia" className="nav-link" style={{color:'#717171', textAlign:'center'}}>
                  <i className="icon-clipboard-list" style={{color:'#717171'}}/><br/> Planes online
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/plan-principiante" className="nav-link" style={{color:'#717171', textAlign:'center'}}>
                  <i className="icon-user-plus" style={{color:'#717171'}}/><br/> Planes descenso de peso
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
</div>
    );
  }
  getNavBar= ()=>{

    const { user } = this.props;
    let navBar;
    const token = localStorage.getItem('token');
    if(!_.isEmpty(user) && token){
      navBar  = this.userNavBar();
    }
    return navBar;
  }



  render() {
    return (
      <>
        {this.getNavBar()}
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state.reducers.homeReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goTo: (where)=>{
      dispatch(push(where));
    },
    back: () => {
      dispatch(goBack());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNavbar);

