import React from 'react';
import { connect } from 'react-redux';
import { getUserById, clearUsuario, updateEditedControl, newControl, getControlById } from 'actions';
import _ from 'lodash';
//import UploadImages from './UploadImages.jsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Loader from 'assets/img/loadern.gif';
import NotificationAlert from 'react-notification-alert';
import { goBack } from 'react-router-redux';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class fichaControl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			control: {
				userId: '',
				avatar: '',
				typo: 'Online',
				talla: '',
				peso: '',
				perimetroCintura: '',
				perimetroCadera: '',
				brazoContraido: '',
				notas: '',
				createdAt: '',
			},
			imagenes: '',
			message: '',
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-control-component');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.control !== state.control) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.control !== this.props.control) {
			this.setState({
				control: this.props.control,
				controlData: this.props.control,
				selectedFiles: null,
			});
		}
		if(prevProps.message!== this.props.message){
			this.notify('br', "Se a guardo correctamente el Control");
		}
	}

	componentDidMount() {
		const { idUsuario, idControl } = this.props.match.params;

		if (idControl) {
			this.props.getControlById(idControl);
			return;
		}

		const newControl = { ...this.state.control, userId: idUsuario };
		this.setState({
			control: newControl,
			selectedFiles: null,
		});
	}

	handleInput = (event) => {
		let newChange = {};
		newChange = { ...this.state.control, [event.target.name]: event.target.value };
		this.setState({ control: newChange });
	};

	//ES PARA IMAGEN
	onDrop = (acceptedFiles) => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}
		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'images') {
			const images = this.state.control.avatar !== '' ? _.split(this.state.control.avatar, ',') : [];
			const imagenesFiltered = _.map(images, (m) => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			this.removeFile(x); // this in case you prefer upload before
			const nuevo_control = { ...this.state.control, avatar: filtered };
			this.setState({ ...this.state, control: nuevo_control });
		} else {
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, (s) => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};

	removeFile = (fileName) => {
		if (fileName) {
			axios
				.post(
					'https://premium-wraper-back.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch((error) => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = (files) => {
		this.setState({
			selectedFiles: files,
		});
	};

	showtheImages = () => {
		const { selectedFiles } = this.state;

		const images = this.state.control.avatar !== '' ? _.split(this.state.control.avatar, ',') : [];
		let oldImages = null;
		oldImages = _.map(images, (x) => {
			if (x !== '' && x !== 'undefined') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={(e) => {
									this.removeImageHandler(e, x, 'images');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/' + x}
						></img>
					</div>
				);
				return img;
			} else return null;
		});

		const returnImages =
			selectedFiles &&
			_.map(selectedFiles, (x) => {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						<img alt="..." src={Loader} width={'70px'} />
						<h6>CARGANDO IMAGENES...</h6>
					</div>
				);
				return img;
			});

		return (
			<div>
				{oldImages}
				{returnImages}
			</div>
		);
	};

	handlerGoBack() {
		this.props.goBack();
	}

	handlerSend = () => {
		const data = new FormData();
		const idControl = this.props.control ? this.props.control._id : null;

		const { control, selectedFiles } = this.state;

		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}

		data.append('userId', control.userId);
		control.typo && data.append('typo', control.typo);
		control.talla && data.append('talla', control.talla);
		control.masaAdiposa && data.append('masaAdiposa', '');
		control.masaMuscular && data.append('masaMuscular', '');
		control.peso && data.append('peso', control.peso);
		control.sumade6 && data.append('sumade6', '');
		control.indice && data.append('indice', '');
		control.biceps && data.append('biceps', '');
		control.objetivo && data.append('objetivo', '');
		control.perimetroCintura && data.append('perimetroCintura', control.perimetroCintura);
		control.perimetroCadera && data.append('perimetroCadera', control.perimetroCadera);
		control.brazoContraido && data.append('brazoContraido', control.brazoContraido);
		control.notas && data.append('notas', control.notas);
		control.avatar && data.append('avatar', control.avatar);
		

		if (idControl) {
			localStorage.setItem('firstControlCompleted', true);
			this.props.updateEditedControl(data, idControl);
		} else {
			localStorage.setItem('firstControlCompleted', true);
			this.props.newControl(data);
		}

	};

	componentUploadImage = () => {
		const maxSize = 3000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed #dadada',
										marginBottom: '30px',
										textAlign: 'center',
										color: '#dadada',
									}}
								>
									{this.props.loading ? (
										<img alt="..." style={{ marginTop: '30px' }} src={Loader} width={'70px'} />
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="icon-line2-picture"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Imagen demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h6>Imagenes Control</h6>
				</div>
				<div>{this.state.control ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};

	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	render() {
		const { idControl } = this.props.match.params;

		return this.props.loading ? (<>
			<div style={{ width: '100%', textAlign: 'center', color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
			
			</>
		) : (
			<>
			<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container>
					<Row className="ml-auto mr-auto text-left">
						<Col className="mr-auto ml-auto" md="12">
							<Button
								className="btn-round"
								color="success"
								style={{ width: '130px', fontSize: '13px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: '#dadada' }}>
									{idControl ? 'Editar Control' : 'Nuevo Control'}
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md="12">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Row>
											<Col className="mr-auto ml-auto" md="9" style={{ padding: '20px' }}>
												{this.componentUploadImage()}
											</Col>
										</Row>
										<Form className="form">
											<Row md="12">
												<Col className="mr-auto ml-auto" md="9">
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-ruler text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Talla"
															name="talla"
															type="text"
															value={this.state?.control?.talla || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-balance-scale text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Peso"
															name="peso"
															type="text"
															value={this.state?.control?.peso || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-ruler text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Perimetro cintura"
															name="perimetroCintura"
															type="text"
															value={this.state?.control?.perimetroCintura || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-ruler text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Perimetro cadera"
															name="perimetroCadera"
															type="text"
															value={this.state?.control?.perimetroCadera || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-ruler text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Brazo contraido"
															name="brazoContraido"
															type="text"
															value={this.state?.control?.brazoContraido || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line-file text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															name="notas"
															cols="40"
															value={this.state?.control?.notas || ''}
															placeholder="Notas"
															rows="2"
															type="textarea"
															onChange={this.handleInput}
														/>
													</InputGroup>
												</Col>
											</Row>
										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="success"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Guardar
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newControl: (data) => dispatch(newControl(data)),
		updateEditedControl: (data, id) => dispatch(updateEditedControl(data, id)),
		getControlById: (id) => dispatch(getControlById(id)),
		goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(fichaControl);
