import React from 'react';
import { connect } from 'react-redux';
import { updateEditedAnamnesis, getAnamnesisById, registrarAnamnesisNuevo } from 'actions';
import _ from 'lodash';
import Loader from 'assets/img/loadern.gif';
import { push, goBack } from 'react-router-redux';
import NotificationAlert from 'react-notification-alert';
import Select from 'react-select';

import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

const initialState = {
	anamnesis: {
		userId:'',
		workHours:'',
		daysWorks:'',
		typeFeedin:'',
		objectives:'',
		phisicsProblems:'',
		operations:'',
		diseases:'',
		pregnancies:'',
		medicines:'',
		medicalNotes:'',
		physicalActivity:'',
		sports:'',
		sportsHours:'',
		sportsDays:'',
        intolerancias:'',
        desayuno_lugar:'',
        desayuno_hora:'',
        desayuno_preparaciones:'',
        almuerzo_lugar:'',
        almuerzo_hora:'',
        almuerzo_preparaciones:'',
        merienda_lugar:'',
        merienda_hora:'',
        merienda_preparaciones:'',
        cena_lugar:'',
        cena_hora:'',
        cena_preparaciones:'',
        colaciones_lugar:'',
        colaciones_hora:'',
        colaciones_preparaciones:'',
        preferencias_lugar:'',
        preferencias_hora:'',
        preferencias_preparaciones:'',
        sabado_lugar:'',
        sabado_hora:'',
        sabado_preparaciones:'',
        domingo_lugar:'',
        domingo_hora:'',
        domingo_preparaciones:'',
        carne_vacuna_frecuencia:'',
        carne_vacuna_cantidad:'',
        pollo_frecuencia:'',
        pollo_cantidad:'',
        cerdo_frecuencia:'',
        cerdo_cantidad:'',
        pescado_frecuencia:'',
        pescado_cantidad:'',
        atun_frecuencia:'',
        atun_cantidad:'',
        huevo_frecuencia:'',
        huevo_cantidad:'',
        lacteos_frecuencia:'',
        lacteos_cantidad:'',
        verduras_frecuencia:'',
        verduras_cantidad:'',
        verduras_detalle:'',
        frutas_frecuencia:'',
        frutas_cantidad:'',
        frutas_detalle:'',
        panificados_frecuencia:'',
        panificados_cantidad:'',
        panificados_detalle:'',
        legumbres_frecuencia:'',
        legumbres_cantidad:'',
        legumbres_detalle:'',
        azucar_frecuencia:'',
        azucar_cantidad:'',
        azucar_detalle:'',
        caldo_frecuencia:'',
        caldo_cantidad:'',
        caldo_detalle:'',
        liquidos_frecuencia:'',
        liquidos_cantidad:'',
        liquidos_detalle:'',
        alimentos_procesados_frecuencia:'',
        alimentos_procesados_cantidad:'',
        alimentos_procesados_detalle:'',
        alcohol_frecuencia:'',
        alcohol_cantidad:'',
        alcohol_detalle:'',
        comida_fuera_frecuencia:'',
        comida_fuera_cantidad:'',
        comida_fuera_detalle:'',
	},
	anamnesisData:[],
    msg: '',
};

class fichaAnamnesis extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anamnesis: {
				userId:'',
				workHours:'',
				daysWorks:'',
				typeFeedin:'',
				objectives:'',
				phisicsProblems:'',
				operations:'',
				diseases:'',
				pregnancies:'',
				medicines:'',
				medicalNotes:'',
				physicalActivity:'',
				sports:'',
				sportsHours:'',
				sportsDays:'',
                intolerancias:'',
                desayuno_lugar:'',
                desayuno_hora:'',
                desayuno_preparaciones:'',
                almuerzo_lugar:'',
                almuerzo_hora:'',
                almuerzo_preparaciones:'',
                merienda_lugar:'',
                merienda_hora:'',
                merienda_preparaciones:'',
                cena_lugar:'',
                cena_hora:'',
                cena_preparaciones:'',
                colaciones_lugar:'',
                colaciones_hora:'',
                colaciones_preparaciones:'',
                preferencias_lugar:'',
                preferencias_hora:'',
                preferencias_preparaciones:'',
                sabado_lugar:'',
                sabado_hora:'',
                sabado_preparaciones:'',
                domingo_lugar:'',
                domingo_hora:'',
                domingo_preparaciones:'',
                carne_vacuna_frecuencia:'',
                carne_vacuna_cantidad:'',
                pollo_frecuencia:'',
                pollo_cantidad:'',
                cerdo_frecuencia:'',
                cerdo_cantidad:'',
                pescado_frecuencia:'',
                pescado_cantidad:'',
                atun_frecuencia:'',
                atun_cantidad:'',
                huevos_frecuencia:'',
                huevos_cantidad:'',
                lacteos_frecuencia:'',
                lacteos_cantidad:'',
                verduras_frecuencia:'',
                verduras_cantidad:'',
                verduras_detalle:'',
                frutas_frecuencia:'',
                frutas_cantidad:'',
                frutas_detalle:'',
                panificados_frecuencia:'',
                panificados_cantidad:'',
                panificados_detalle:'',
                legumbres_frecuencia:'',
                legumbres_cantidad:'',
                legumbres_detalle:'',
                azucar_frecuencia:'',
                azucar_cantidad:'',
                azucar_detalle:'',
                caldo_frecuencia:'',
                caldo_cantidad:'',
                caldo_detalle:'',
                liquidos_frecuencia:'',
                liquidos_cantidad:'',
                liquidos_detalle:'',
                alimentos_procesados_frecuencia:'',
                alimentos_procesados_cantidad:'',
                alimentos_procesados_detalle:'',
                alcohol_frecuencia:'',
                alcohol_cantidad:'',
                alcohol_detalle:'',
                comida_fuera_frecuencia:'',
                comida_fuera_cantidad:'',
                comida_fuera_detalle:'',
			},
			anamnesisData:[],
			msg: '',
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-anamnesis-component');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.anamnesis !== state.anamnesis) {
			return {
			};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.anamnesis !== this.props.anamnesis) {
			this.setState({
				anamnesis:this.props.anamnesis,
				anamnesisData:this.props.anamnesis,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
			if (id) {
				this.props.getAnamnesisById(id);
				return;
			}
	}

	handlerSelect = (value , type) => {
		const toChange = value.value;
		const { anamnesis } = this.state;
		anamnesis[type] = toChange;
		this.setState({...this.state, anamnesis });
	}

	handleInput = (event) => {
		const {anamnesis} = this.state;
		anamnesis[event.target.name] = event.target.value;
		this.setState({ anamnesis });
	};

	handlerSend = () => {
		const data = this.state.anamnesis;
		const idAnamnesis = this.state.anamnesis._id;
		const id = !_.isEmpty(this.props.usuario) ? this.props.usuario._id : this.props.match.params.id;

		if (idAnamnesis){
			localStorage.setItem('anamnesisCompleted', true);
			this.props.updateEditedAnamnesis(data, id);
			this.notify('br', "Se a guardo correctamente la Anamnesis");
		} else {
			data.userId = id;
			localStorage.setItem('anamnesisCompleted', true);
			this.props.registrarAnamnesisNuevo(data);
			this.notify('br', "Se a guardo correctamente la Anamnesis");
            }
	};

	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b></b>
						<h5>{message}</h5>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handlerNuevoControl() {
		console.log('#Control');
	}
	handlerEnviarMensaje() {
		console.log('#Mensaje');
	}

	handlerGoBack() {
		this.props.goBack();
	}

	handlerNuevoAnamnesis() {
		console.log('#Anamnesis');
	}

	render() {
        const { id: idUsuario } = this.props.match.params;
        const { anamnesis } = this.state;

		return this.props.loading || !anamnesis ? (
			<div style={{ width: '100%', textAlign: 'center',color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
		) : (
			<>
			<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container>
				<Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: '#dadada' }}>
									{idUsuario ? `Formulario Anamnesis Offline` : 'Nueva ficha Anamnesis Offline'}
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md="12">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Form className="form">
                                        <Row md="12">
										<Col className="mr-auto" md="6">
										<h4>Trabajo</h4>
										<h5>Agrega Profesión / tareaque realiza, horario y dias de trabajo y/o estudio</h5>
										<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-clock text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Profesion / estudio .. Horas de trabajo"
															name="workHours"
															type="text"
															value={anamnesis.workHours}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-calendar text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Días de trabajo"
															name="daysWorks"
															type="text"
															value={anamnesis.daysWorks}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h4>Indica que tipo de Alimentación tienes</h4>
													<h5>–Vegano (sólo consume alimentos de origen vegetal)<br />
													– ovo- vegetariano (consume alimentos de origen vegetal y huevos) <br />
													– ovolacto-vegetariano (consume alimentos de origen vegetal huevo y lácteos) <br />
													– omnívoro (consume alimentos de origen animales y vegetal)</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="typeFeedin"
														value={{ value: anamnesis.typeFeedin, label: anamnesis.typeFeedin }}
														onChange={value => this.handlerSelect(value , "typeFeedin")}
														options={[
															{ value: 'Vegano', label: 'Vegano' },
															{ value: 'Ovo-vegetariano', label: 'Ovo-vegetariano' },
															{ value: 'Ovolacto-vegetariano', label: 'Ovolacto-vegetariano' },
															{ value: 'Omnívoro', label: 'Omnívoro' },
														]}
														placeholder="Tipo de alimentacion"
													/>
													<h4>Aspectos de Salud</h4>
													<h5>Si presenta describirlas, en caso de no presentar escribir no presenta.</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-file-medical-alt text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Problemas Físicos musculares, articulares, hernia"
															name="phisicsProblems"
															type="text"
															value={anamnesis.phisicsProblems}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-notes-medical text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Operaciones/ cirugías reseñables"
															name="operations"
															type="text"
															value={anamnesis.operations}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h5>Enfermedades crónicas (diabetes, Hipertensión, colesterol alto) </h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-file-medical text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Enfermedades, crónicas"
															name="diseases"
															type="text"
															value={anamnesis.diseases}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h5>Enbarazo en la actualidad o en los últimos 3 meses </h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-female1 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Embarazos "
															name="pregnancies"
															type="text"
															value={anamnesis.pregnancies}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h5>Está tomando actualmente algún tipo de medicación?</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-eyedropper text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Medicamentos"
															name="medicines"
															type="text"
															value={anamnesis.medicines}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-medrt text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Notas Medicas"
															name="medicalNotes"
															type="text"
															value={anamnesis.medicalNotes}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
												</Col>
												<Col className="mr-auto" md="6">
												<h4>Objetivo o motivo de la consulta</h4>
												<h5>Bajar grasa corporal<br />
													Aumentar masa muscular<br />
													Alimentación saludable<br />
													Alimentación en deporte</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="objetivos"
														value={{ value: anamnesis.objectives, label: anamnesis.objectives }}
														onChange={value => this.handlerSelect(value , "objectives")}
														options={[
															{ value: 'Bajar grasa corporal', label: 'Bajar grasa corporal' },
															{ value: 'Aumentar masa muscular', label: 'Aumentar masa muscular' },
															{ value: 'Alimentación saludable', label: 'Alimentación saludable' },
															{ value: 'Alimentación en deporte', label: 'Alimentación en deporte' },
														]}
														placeholder="Objetivos"
													/>


													<h4>Actividad física</h4>
													<h5>Tipo de actividad física que realizas, horario en cual lo practicas, tiempo que demora el entrenamiento y frecuencia de entrenamiento</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-walking text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Actividad Fisica"
															name="physicalActivity"
															type="text"
															value={anamnesis.physicalActivity}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-futbol1 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Deportes"
															name="sports"
															type="text"
															value={anamnesis.sports}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-clock2 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Horario de entrenamiento"
															name="sportsHours"
															type="text"
															value={anamnesis.sportsHours}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-calendar21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Dias de deporte"
															name="sportsDays"
															type="number"
															min='0'
															value={anamnesis.sportsDays}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
										        </Col>
                                                </Row>
												<Row style={{marginTop:"20px", padding:'40px'}} md="12">
												<Col className="mr-auto" style={{textAlign:'center'}} md="12">
												<h4>Consumo habitual</h4>
											<h5>En el siguiente cuestionario debes ingresar lo que consumís habitualmente en cada tiempo de comida, con horario y lugar donde realizas cada ingesta. Al menos 3 o 4 preparaciones para cada tiempo de comida.</h5>
											</Col>
											</Row>
											<Row style={{marginTop:"20px"}} md="12">
												<Col className="mr-auto" md="6">
                                                    <h4>Desayuno</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="desayuno_lugar"
															type="text"
															value={anamnesis.desayuno_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="desayuno_hora"
															type="text"
															value={anamnesis.desayuno_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                        <Input
                                                            name="desayuno_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.desayuno_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                                    </Col>
                                                    <Col className="mr-auto" md="6">
                                                    <h4>Almuerzo</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                                <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="lugar"
															name="almuerzo_lugar"
															type="text"
															value={anamnesis.almuerzo_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                               <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="almuerzo_hora"
															type="text"
															value={anamnesis.almuerzo_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                        <Input
                                                            name="almuerzo_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.almuerzo_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                                    </Col>
                                                    </Row>
                                                <Row style={{marginTop:"20px"}}  md="12">
                                                    <Col className="mr-auto" md="6">
                                                    <h4>Merienda</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="merienda_lugar"
															type="text"
															value={anamnesis.merienda_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="merienda_hora"
															type="text"
															value={anamnesis.merienda_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                        <Input
                                                            name="merienda_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.merienda_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
												</Col>
                                                <Col className="mr-auto" md="6">
                                                    <h4>Cena</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="cena_lugar"
															type="text"
															value={anamnesis.cena_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="cena_hora"
															type="text"
															value={anamnesis.cena_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="cena_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.cena_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
												</Col>
											</Row>
                                            <Row style={{marginTop:"20px"}} md="12">
                                            <Col className="mr-auto" md="6">
                                                    <h4>Colaciones</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="colaciones_lugar"
															type="text"
															value={anamnesis.colaciones_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="colaciones_hora"
															type="text"
															value={anamnesis.colaciones_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                        <Input
                                                            name="colaciones_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.colaciones_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
												</Col>
                                                <Col className="mr-auto" md="6">
                                                    <h4>Preferencias</h4>
													<h5> Describir alimentos que sean más consumidos durante la semana</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="preferencias_lugar"
															type="text"
															value={anamnesis.preferencias_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="preferencias_hora"
															type="text"
															value={anamnesis.preferencias_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                          <Input
                                                            name="preferencias_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.preferencias_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>

													<h4>Intolerancias</h4>
													<h5>  Describir alimentos que no toleres o no te gusten</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-align-right1 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="intolerancias"
                                                            cols="100"
                                                            value={anamnesis.intolerancias || ''}
                                                            placeholder="Intolerancias"
                                                            rows="10"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
												</Col>
                                                </Row>
												<Row style={{marginTop:"20px", padding:'40px'}} md="12">
													<Col className="mr-auto" style={{textAlign:'center'}} md="12">
													<h4>Fin de semana</h4>
													<h5> Describir la alimentación que llevas en el fin de semana Y los horarios de cada comida Describir viernes a la noche, sábado todo el día y domingo todo el día Lo que acostumbras a consumir habitualmente</h5>
													</Col>
												</Row>
                                                <Row style={{marginTop:"20px"}} md="12">
											    <Col className="mr-auto" md="6">
                                                    <h4>Sabado</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="sabado_lugar"
															type="text"
															value={anamnesis.sabado_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="sabado_hora"
															type="text"
															value={anamnesis.sabado_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                                <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                        <Input
                                                            name="sabado_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.sabado_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
												</Col>
                                                <Col className="mr-auto" md="6">
                                                    <h4>Domingo</h4>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-location text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Lugar"
															name="domingo_lugar"
															type="text"
															value={anamnesis.domingo_lugar}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-clock21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Hora"
															name="domingo_hora"
															type="text"
															value={anamnesis.domingo_hora}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
                                                        <Input
                                                            name="domingo_preparaciones"
                                                            cols="40"
                                                            value={anamnesis.domingo_preparaciones || ''}
                                                            placeholder="Preparaciones"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
												</Col>
                                            </Row>
											<Row style={{marginTop:"20px", padding:'40px'}} md="12">
													<Col className="mr-auto" style={{textAlign:'center'}} md="12">
													<h4>Frecuencia de alimentos</h4>
													<h5> Describir frecuencia de consumo de cada tipo de carne, también de huevos, lácteos, verduras, frutas etc.</h5>
													<h5>Las opciones son: todos los días, determinadas veces por semana o 1 vez cada dos semanas.</h5>
													</Col>
												</Row>
                                            <Row style={{marginTop:"20px"}} md="12">
                                            <Col className="mr-auto" md="3">
                                            <h4>Carne vacuna</h4>
				
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="carne_vacuna_frecuencia"
														value={{ value: anamnesis.carne_vacuna_frecuencia, label: anamnesis.carne_vacuna_frecuencia }}
														onChange={value => this.handlerSelect(value , "carne_vacuna_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="carne_vacuna_cantidad"
															type="number"
															min='0'
															value={anamnesis.carne_vacuna_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="3">
                                            <h4>Pollo</h4>
											<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="carne_vacuna_frecuencia"
														value={{ value: anamnesis.pollo_frecuencia, label: anamnesis.pollo_frecuencia }}
														onChange={value => this.handlerSelect(value , "pollo_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="pollo_cantidad"
															type="number"
															min='0'
															value={anamnesis.pollo_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="3">
                                            <h4>Cerdo</h4>
											<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="carne_vacuna_frecuencia"
														value={{ value: anamnesis.cerdo_frecuencia, label: anamnesis.cerdo_frecuencia}}
														onChange={value => this.handlerSelect(value , "cerdo_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="cerdo_cantidad"
															type="number"
															min='0'
															value={anamnesis.cerdo_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="3">
                                            <h4>Pescado</h4>
											<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="pescado_frecuencia"
														value={{ value: anamnesis.pescado_frecuencia, label: anamnesis.pescado_frecuencia}}
														onChange={value => this.handlerSelect(value , "pescado_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
															<i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="pescado_cantidad"
															type="number"
															min='0'
															value={anamnesis.pescado_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            </Row>
                                            <Row style={{marginTop:"20px"}} md="12">
                                            <Col className="mr-auto" md="4">
                                            <h4>Atun</h4>
											<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="atun_frecuencia"
														value={{ value: anamnesis.atun_frecuencia, label: anamnesis.atun_frecuencia}}
														onChange={value => this.handlerSelect(value , "atun_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="atun_cantidad"
															type="number"
															min='0'
															value={anamnesis.atun_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Huevos</h4>
											<h5> Cuantas veces x semana consumís? <br />En que preparaciones?</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="huevos_frecuencia"
														value={{ value: anamnesis.huevos_frecuencia, label: anamnesis.huevos_frecuencia}}
														onChange={value => this.handlerSelect(value , "huevos_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="huevos_cantidad"
															type="number"
															min='0'
															value={anamnesis.huevos_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Lacteos</h4>
											<h5> Consumís leche y yogur? Cuales?</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="lacteos_frecuencia"
														value={{ value: anamnesis.lacteos_frecuencia, label: anamnesis.lacteos_frecuencia}}
														onChange={value => this.handlerSelect(value , "lacteos_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="lacteos_cantidad"
															type="number"
															min='0'
															value={anamnesis.lacteos_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                            </Col>
                                            </Row>
                                            <Row style={{marginTop:"20px"}} md="12">
                                            <Col className="mr-auto" md="4">
                                            <h4>Verduras</h4>
											<h5>Cuántas veces x semana consumís? <br />Cuáles son las que más consumís?</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="verduras_frecuencia"
														value={{ value: anamnesis.verduras_frecuencia, label: anamnesis.verduras_frecuencia}}
														onChange={value => this.handlerSelect(value , "verduras_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad en gramos"
															name="verduras_cantidad"
															type="number"
															min='0'
															value={anamnesis.verduras_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                                <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="verduras_detalle"
                                                            cols="40"
                                                            value={anamnesis.verduras_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Frutas</h4>
											<h5> Si consumís todos los días, cuántas unidades cada día?<br />Y cuáles con más frecuencia?</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="frutas_frecuencia"
														value={{ value: anamnesis.frutas_frecuencia, label: anamnesis.frutas_frecuencia}}
														onChange={value => this.handlerSelect(value , "frutas_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad"
															name="frutas_cantidad"
															type="number"
															min='0'
															value={anamnesis.frutas_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="frutas_detalle"
                                                            cols="40"
                                                            value={anamnesis.frutas_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Panificados</h4>
											<h5>Que tipo de pan consumís?</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="panificados_frecuencia"
														value={{ value: anamnesis.panificados_frecuencia, label: anamnesis.panificados_frecuencia}}
														onChange={value => this.handlerSelect(value , "panificados_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad"
															name="panificados_cantidad"
															type="number"
															min='0'
															value={anamnesis.panificados_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="panificados_detalle"
                                                            cols="40"
                                                            value={anamnesis.panificados_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            </Row>
                                            <Row style={{marginTop:"20px"}} md="12">
                                            <Col className="mr-auto" md="4">
                                            <h4>Legumbres</h4>
											<h5> Consumís lentejas, garbanzos, porotos? <br />En que preparaciones?</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="legumbres_frecuencia"
														value={{ value: anamnesis.legumbres_frecuencia, label: anamnesis.legumbres_frecuencia}}
														onChange={value => this.handlerSelect(value , "legumbres_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
															<i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad gramos"
															name="legumbres_cantidad"
															type="number"
															min='0'
															value={anamnesis.legumbres_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="legumbres_detalle"
                                                            cols="40"
                                                            value={anamnesis.legumbres_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Azucar</h4>
											<h5> Agregas azúcar a preparaciones o infusiones <br /> Consumís alfajores, galletas dulces o chocolates a diario?</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="azucar_frecuencia"
														value={{ value: anamnesis.azucar_frecuencia, label: anamnesis.azucar_frecuencia}}
														onChange={value => this.handlerSelect(value , "azucar_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad gramos"
															name="azucar_cantidad"
															type="number"
															min='0'
															value={anamnesis.azucar_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="azucar_detalle"
                                                            cols="40"
                                                            value={anamnesis.azucar_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Caldo, sal, sopas instantáneas</h4>
											<h5>Frecuencia de consumo</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="caldo_frecuencia"
														value={{ value: anamnesis.caldo_frecuencia, label: anamnesis.caldo_frecuencia}}
														onChange={value => this.handlerSelect(value , "caldo_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad ej 250cc"
															name="caldo_cantidad"
															type="text"
									
															value={anamnesis.caldo_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="caldo_detalle"
                                                            cols="40"
                                                            value={anamnesis.caldo_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            </Row>

                                            <Row style={{marginTop:"20px"}} md="12">
                                            <Col className="mr-auto" md="4">
                                            <h4>Liquidos</h4>
											<h5>Agua, refrescos y juegos</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="liquidos_frecuencia"
														value={{ value: anamnesis.liquidos_frecuencia, label: anamnesis.liquidos_frecuencia}}
														onChange={value => this.handlerSelect(value , "liquidos_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad"
															name="liquidos_cantidad"
															type="text"
															value={anamnesis.liquidos_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="liquidos_detalle"
                                                            cols="40"
                                                            value={anamnesis.liquidos_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Alimentos Procesados</h4>
											<h5>Consumís hamburguesas sneck o similar? Nuggets? <br /> 
											panchos, chorizos, salame, panceta?</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="alimentos_procesados_frecuencia"
														value={{ value: anamnesis.alimentos_procesados_frecuencia, label: anamnesis.alimentos_procesados_frecuencia}}
														onChange={value => this.handlerSelect(value , "alimentos_procesados_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad gramos"
															name="alimentos_procesados_cantidad"
															type="number"
															min='0'
															value={anamnesis.alimentos_procesados_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="alimentos_procesados_detalle"
                                                            cols="40"
                                                            value={anamnesis.alimentos_procesados_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            <Col className="mr-auto" md="4">
                                            <h4>Bebidas Alcohólicas</h4>
											<h5>Frecuencia de consume</h5>
											
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="alcohol_frecuencia"
														value={{ value: anamnesis.alcohol_frecuencia, label: anamnesis.alcohol_frecuencia}}
														onChange={value => this.handlerSelect(value , "alcohol_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad"
															name="alcohol_cantidad"
															type="text"
															value={anamnesis.alcohol_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
                                                            <i
																	className="icon-note text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="alcohol_detalle"
                                                            cols="40"
                                                            value={anamnesis.alcohol_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
                                            </Row>
											<Row style={{marginTop:"20px"}} md="12">
											
                                                <Col className="mr-auto" md="6">
                                            <h4>Comidas Fuera</h4>
											<h5>Salís a comer durante la semana o el fin de semana? <br /> Cuantas veces?</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="comida_fuera_frecuencia"
														value={{ value: anamnesis.comida_fuera_frecuencia, label: anamnesis.comida_fuera_frecuencia}}
														onChange={value => this.handlerSelect(value , "comida_fuera_frecuencia")}
														options={[
															{ value: '1 vez cada dos semanas', label: '1 vez cada dos semanas' },
															{ value: 'Determinadas veces por semana', label: 'Determinadas veces por semana' },
															{ value: 'Todos los días', label: 'Todos los días' },
														]}
														placeholder="Frecuencia"
													/>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-data text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Cantidad"
															name="comida_fuera_cantidad"
															type="number"
															min='0'
															value={anamnesis.comida_fuera_cantidad}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
                                                    <InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-food text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
                                                            name="comida_fuera_detalle"
                                                            cols="40"
                                                            value={anamnesis.comida_fuera_detalle || ''}
                                                            placeholder="Detalle"
                                                            rows="2"
                                                            type="textarea"
                                                            onChange={this.handleInput}
                                                        />
													</InputGroup>
                                            </Col>
											<Col className="mr-auto" md="6">                                                
												</Col>
											</Row>

										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="warning"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Guardar Anamnesia
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEditedAnamnesis: (data, id) => dispatch(updateEditedAnamnesis(data, id)),
		getAnamnesisById: (id) => dispatch(getAnamnesisById(id)),
		registrarAnamnesisNuevo: (data) => dispatch(registrarAnamnesisNuevo(data)),
		goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(fichaAnamnesis);
