import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store, history } from './store';
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import RTLLayout from "layouts/RTL/RTL.jsx";

import 'assets/icons/et-line.css';
import 'assets/icons/font-icons.css';
import 'assets/icons/font-icons-realstate.css';

import 'assets/css/nucleo-icons.css';
import 'assets/css/black-dashboard-pro-react.css';
import 'assets/demo/demo.css';
import 'react-notification-alert/dist/animate.css';

//const hist = createBrowserHistory();

const rootElement = document.getElementById("root");
ReactDOM.hydrate(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/rtl" render={props => <RTLLayout {...props} />} />
        <Redirect from="/" to="/auth/home" />
        <Redirect from="/quien-soy" to="/auth/quien-soy" />
        <Redirect from="/turnos-presenciales" to="/auth/turnos-presenciales" />
        <Redirect from="/plan-principiante" to="/auth/plan-principiante" />
        <Redirect from="/planes-a-distancia" to="/auth/planes-a-distancia" />
        <Redirect from="/contacto" to="/auth/contacto" />
        <Redirect from="/registrarme" to="/auth/registrarme" />
        <Redirect from="/login" to="/auth/login" />
        <Redirect from="/mensajes" to="/auth/mensajes" />
        <Redirect from="/administracion" to="/auth/administracion" />
        <Redirect from="/auth/administracion/usuario" to="/auth/usuario" />
        <Redirect from="/usuario" to="/auth/usuario" />
        <Redirect from="/dashboard" to="/admin/dashboard" />

      </Switch>
    </Router>
  </Provider>, rootElement);