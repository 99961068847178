import React from 'react';
import { connect } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import {getPlanes, deletePlan} from 'actions';

import { push, goBack } from 'react-router-redux';

import classnames from 'classnames';
import Loader from 'assets/img/loadern.gif';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
} from 'reactstrap';

class Planes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			buscar: '',
			orderBy: 'desc',
		};
	}

	componentDidMount() {
		document.body.classList.toggle('planes-component');
		this.props.getPlanes();
	}
	componentWillUnmount() {
		document.body.classList.toggle('planes-component');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	editPlanes = (id) => {
		console.log(id);
		this.props.goToEditPlan(id);
	};

	verPlan = (id) => {
		this.props.irDetallesPlan(id);
	};
	
	addArchivo = (id) => {
		this.props.irAgregarFile(id);
	};

	removePlan = (id) => {
		console.log(`Borrar ${id}`);
		this.props.deletePlan(id);
    };

	listPlanes = () => {
	const { planes } = this.props;

	const filterByTextTitle = _.filter(planes, x =>
		this.reemplazarAcentos(x.title.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const filterByTextDescription = _.filter(planes, x =>
		this.reemplazarAcentos(x.description.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const listFiltered = _.uniq((_.concat(filterByTextTitle, filterByTextDescription)));


	const flOrder = this.state.orderByStateValue
			? _.orderBy(listFiltered, 'state', this.state.orderByState)
			: listFiltered;

	const lista = _.map(flOrder , (x)=>{
		return (
			<tr key={x._id}>
				<td>{x.title}</td>
				<td>{x.type}</td>
				<td className="text-right">${x.costPesos}</td>
				<td className="text-right">
				<Button
						className="btn-link"
						color="info"
						id="tooltip9741712024"
						onClick={() => this.addArchivo(x._id)}
						size="sm"
					>
						<i className="icon-file-add" />
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip9741712024">
						Agregar Archivos
					</UncontrolledTooltip>
					<Button
						className="btn-link btn-icon"
						color="success"
						id="tooltip324367706"
						size="sm"
						onClick={() => this.editPlanes(x._id)}
					>
						<i className="tim-icons icon-pencil" />
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip324367706">
						Editar
					</UncontrolledTooltip>
					<Button
						className="btn-link"
						color="warning"
						id="tooltip974171204"
						onClick={() => this.verPlan(x._id)}
						size="sm"
					>
						<i className="icon-line-eye" />
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip974171204">
						Ver
					</UncontrolledTooltip>
					<Button
						className="btn-link"
						color="danger"
						id="tooltip974171201"
						onClick={() => this.removePlan(x._id)}
						size="sm"
					>
						<i className="tim-icons icon-simple-remove" />
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip974171201">
						Eliminar
					</UncontrolledTooltip>
				</td>
			</tr>
		)
       });
	   return (
		   <tbody>
		   {lista}
		   </tbody>
	   ); 
   };
   
   change = (e) => {
	 this.setState({buscar:e.target.value})
   }
   
   handlerStatus=(value)=>{
	   if (value) {
		   this.setState({ ...this.state, orderByState: 'desc', orderByStateValue: true });
	   } else {
		   this.setState({ ...this.state, orderByState: '', orderByStateValue: false });
	   }
   }

   reemplazarAcentos = cadena => {
	const chars = {
		á: 'a',
		é: 'e',
		í: 'i',
		ó: 'o',
		ú: 'u',
		à: 'a',
		è: 'e',
		ì: 'i',
		ò: 'o',
		ù: 'u',
		ñ: 'n',
		Á: 'A',
		É: 'E',
		Í: 'I',
		Ó: 'O',
		Ú: 'U',
		À: 'A',
		È: 'E',
		Ì: 'I',
		Ò: 'O',
		Ù: 'U',
		Ñ: 'N',
	};
	const expr = /[áàéèíìóòúùñ]/gi;
	const res = cadena.replace(expr, e => {
		return chars[e];
	});
	return res;
};
handlerNuevoPlan = () =>{
	this.props.nuevoPlan();
}

	render() {
		const { loading } = this.props;
		return (
			loading ? <div><img src={Loader} width="100px" alt=""/>loading...</div>
				:
				(
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>

				<div className="content">
					<Row>
						<Col md="12" className="ml-auto mr-auto text-left">
							<Button
								style={{ margin: '30px', width: '200px' }}
								className="btn-round"
								color="success"
								onClick={() => this.handlerNuevoPlan()}
								size="lg"
							>
							<i className="tim-icons icon-simple-add" /> {" "} Nuevo Evento
							</Button>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<div className="tools float-right">
										<InputGroup
											className={classnames(this.state.buscarState, {
												'input-group-focus': this.state.buscarFocus,
											})}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="tim-icons icon-zoom-split" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												name="buscar"
												placeholder="Buscar..."
												type="text"
												onChange={(e) => this.change(e)}
												onFocus={(e) => this.setState({ buscarFocus: true })}
												onBlur={(e) => this.setState({ buscarFocus: false })}
											/>
										</InputGroup>
									</div>
									<CardTitle tag="h4" className="text-left">Listado Eventos</CardTitle>
								</CardHeader>
								<CardBody>
									<Table responsive style={{ overflow: 'none' }}>
										<thead className="text-primary">
											<tr>
												<th className="text-center">Titulo</th>
												<th className="text-center">Tipo</th>
												<th className="text-right">Precio</th>
												<th className="text-right">Acciones</th>
											</tr>
										</thead>
										{this.listPlanes()}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		)
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPlanes: () => dispatch(getPlanes()),
		nuevoPlan: () => dispatch(push('/auth/nuevo-plan')),
		goToEditPlan: (id) =>(dispatch(push(`/auth/plan/${id}`))),
		deletePlan: (id) =>(dispatch(deletePlan(id))),
		irDetallesPlan:(id) => dispatch(push(`/auth/planes/${id}`)),
		irAgregarFile: (id) => dispatch(push(`/auth/producto/${id}`))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Planes);