import React from 'react';
import { connect } from 'react-redux';
import { getUserById, clearUsuario } from 'actions';
import _ from 'lodash';
import Loader from 'assets/img/loadern.gif';
import { goBack } from 'react-router-redux';
import { updateEditedFile, getFileById, newFile } from 'actions';



import Dropzone from 'react-dropzone';
import axios from 'axios';

// reactstrap components
import { Button, Card, CardBody, CardFooter, Form, Container, Row, Col, } from 'reactstrap';


class FileUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: {
                avatar: null,
                idProduct:''
            },
			fileData: [],
			imagenes: '',
			msg: '',
			editMode: false,
			filesToSend:''
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-files-component');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.file !== state.file) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.file !== this.props.file) {
			this.setState({
				file: this.props.file,
				fileData: this.props.file,
				editMode: true,
                selectedFiles: null,
			});
		}
	}

	componentDidMount() {
        const { id } = this.props.match.params;
		if (id) {
			this.props.getFileById(id);
			return;
		}

		this.setState({
			selectedFiles: null,
		});
	}



	//ES PARA IMAGEN
	onDrop = (acceptedFiles) => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}
		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'file') {
			const images = this.state.file.avatar !== '' ? _.split(this.state.file.avatar, ',') : [];
			const imagenesFiltered = _.map(images, (m) => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			this.removeFile(x); // this in case you prefer upload before
			const nueva_files = { ...this.state.file, avatar: filtered };
			this.setState({ ...this.state, file: nueva_files });
		} else {
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, (s) => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};

	removeFile = (fileName) => {
		if (fileName) {
			axios
				.post(
					'https://premium-wraper-back.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch((error) => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = (files) => {
		this.setState({
			selectedFiles: files,
		});
	};

	showtheImages = () => {
		const { selectedFiles } = this.state;

		const images = this.state.file.avatar !== '' ? _.split(this.state.file.avatar, ',') : [];
		let oldImages = null;
		oldImages = _.map(images, (x) => {
			if (x !== '' && x !== 'undefined') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={(e) => {
									this.removeImageHandler(e, x, 'file');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={'https://s3-sa-east-1.amazonaws.com/vivipiria.uy/iconopdf.png'}
						></img>
                        <a href={`https://s3-sa-east-1.amazonaws.com/vivipiria.uy/${x}`}>Descargar archivo </a>
					</div>
				);
				return img;
			} else return null;
		});

		const returnImages =
			selectedFiles &&
			_.map(selectedFiles, (x) => {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						<img alt="..." src={Loader} width={'70px'} />
						<h6>CARGANDO IMAGENES...</h6>
					</div>
				);
				return img;
			});

		return (
			<div>
				{oldImages}
				{returnImages}
			</div>
		);
	};


	handlerSelect = (value, type) => {
		const toChange = value.value;
		const { file } = this.state;
		file[type] = toChange;
		this.setState({ ...this.state, file });
	};

	handleInput = (event) => {
		const { file } = this.state;
		file[event.target.name] = event.target.value;
		this.setState({ file });
	};


	handleCheck = (event) => {
		const { file } = this.state;
		file[event.target.name] = !file[event.target.name];
		this.setState({ file });
	};


	handlerSend = () => {
		const data = new FormData();
        const { id } = this.props.match.params;
        const idFiles = this.props.file ? this.props.file._id : null;

		const { file, selectedFiles } = this.state;

		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}

		file?.avatar && data.append('avatar', file.avatar);
		data.append('idProduct', id);

		if (idFiles) {
			this.props.updateEditedFile(data, idFiles);
		} else {
			this.props.newFile(data);
		}
	};

	componentUploadImage = () => {
		const maxSize = 3000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg, application/pdf" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed #dadada',
										marginBottom: '30px',
										textAlign: 'center',
										color: '#dadada',
									}}
								>
									{this.props.loading ? (
										<img alt="..." style={{ marginTop: '30px' }} src={Loader} width={'70px'} />
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="icon-line2-picture"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Archivo demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h6>Imagenes Files</h6>
				</div>
				<div>{this.state.file ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};





	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b></b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handlerGoBack() {
		this.props.goBack();
	}

	render() {

		return this.props.loading ? (
			<div style={{ width: '100%', textAlign: 'center', color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
		) : (
			<>
				<Container>
					<Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: '#dadada' }}>
								Agregar file
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md="9">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Row>
											<Col className="mr-auto ml-auto" md="12" style={{ padding: '20px' }}>
												{this.componentUploadImage()}
											</Col>
										</Row>
										<Form className="form">
											<Row md="12">
												<Col className="mr-auto ml-auto" md="12">
													
												</Col>
											</Row>
										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="warning"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Guardar File
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newFile: (data) => dispatch(newFile(data)),
		updateEditedFile: (data, id) => dispatch(updateEditedFile(data, id)),
		getFileById: (id) => dispatch(getFileById(id)),
		goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);