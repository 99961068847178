import React from "react";
import { connect } from 'react-redux';
// react component used to create a calendar with events on it
import { Calendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar

import NotificationAlert from 'react-notification-alert';
import moment from "moment";

import { Card, CardBody, Row, Col } from "reactstrap";
import {push} from 'react-router-redux';

import {getAgenda, newCalendar, updateEditedCalendar, removeControl} from 'actions';


import Loader from 'assets/img/loadern.gif';
import ReactBSAlert from 'react-bootstrap-sweetalert';


import {
  Form,
  Input,
} from 'reactstrap';


require('moment/locale/es.js');
const localizer = momentLocalizer(moment);

class AgendaSoloUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendar:[],
      alert: null,
      evento:[{
        title:'Turno Presencial',
        email:'',
        color:'green',
        description:'Turno presencial'
      },],
      msg: '',
			buscar: '',
    };
  }


	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.calendar !== this.props.calendar) {
			this.setState({
				calendar: this.props.calendar,
			});
    }
    if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	componentDidMount() {
    this.props.getAgenda();
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
  };

  handleInput = (event) => {
    console.log(event.target.value);
		const {evento} = this.state;
		evento[event.target.name] = event.target.value;
    this.setState({ evento });
	};

  
	editUser = (id) => {
		console.log(`Editar ${id}`);
	};

	verUser = (id) => {
		console.log(`Ver ${id}`);
	};

	removeUser = (id) => {
		console.log(`Borrar ${id}`);
    };
    
    handlerCoso(value) {
		this.inputConfirmAlert(value);
	}

	inputConfirmAlert = (value) => {
		setTimeout(this.inputConfirmAlertNext(value), 200);
	};

	inputConfirmAlertNext = (value) => {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					onConfirm={() => this.hideAlert2()}
					onCancel={() => this.hideAlert2()}
					confirmBtnBsStyle="success"
					btnSize=""
					title="Control agendado para: "
				>
					<b>{value}</b>
				</ReactBSAlert>
			),
		});
	};

  addNewEventAlert = slotInfo => {
    const {evento} = this.state;
    this.setState({
      alert: (
        <ReactBSAlert
					style={{ display: 'block', marginTop: '-300px',height: '580px' }}
					title="Agendar Cita/Control"
					onCancel={() => this.hideAlert()}
					onConfirm={e => this.addNewEvent(e, slotInfo)}
					showCancel = {true}
					showConfirm= {true}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
					showCloseButton
				>
        <Form className="form">
						<h3 style={{color:'#000'}}>Dia y hora:</h3>
                        <h3 style={{color:'#000'}}>{`${moment(slotInfo.start).format('l')} - ${moment(slotInfo.start).format('LT')} a ${moment(slotInfo.end).format('LT')}` }</h3>						
                          <p style={{ color:'#000'}} >Confirmanos tu Email</p>
														<Input
															placeholder=""
															name="email"
															type="email"
															value={evento.email}
															onChange={(e)=> this.handleInput(e)}
															style={{ color:'#000', height: '45px', borderColor:'#000'}}
														/>
                          <br />
                          <p style={{ color:'#000'}} >Descripcion o motivos (opcional)</p>
													<Input
														name="description"
														cols="20"
                            style={{color:'#000', padding:'10px'}}
														value={evento.description}
														placeholder=""
														rows="2"
														type="textarea"
														onChange={this.handleInput}
													/>
                          
                          </Form>
                          
        </ReactBSAlert>
      )
    });
  };


  addNewEvent = (e, slotInfo) => {
    const data = {
      title:'Turno Presencial',
      email:this.state.evento.email,
      color:this.state.evento.color,
      description:this.state.evento.description,
      allDay:false,
      start:slotInfo.start,
      end:slotInfo.end,

    };
    //console.log(data);
    this.handlerCoso(`${moment(slotInfo.start).format('l')} - ${moment(slotInfo.start).format('LT')} a ${moment(slotInfo.end).format('LT')}`);
    this.props.newCalendar(data);
    
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      evento:[{
        title:'',
        email:'',
        color:'',
        description:''
      },]
    });
  };

  hideAlert2 = () => {
    this.setState({
      alert: null,
      evento:[{
        title:'',
        email:'',
        color:'',
        description:''
      },]
    });
    this.props.goHome();
  };


  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };


  render() {
    const { loading } = this.props;
		return (
			loading ? <div><img src={Loader} width="100px" alt=""/>loading...</div>
				:
				(
      <>
      <div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-calendar">
                <CardBody>
                  <Calendar
                    culture='es'
                    selectable
                    localizer={localizer}
                    step={60}
                    timeslots={1}
                    min={new Date(0, 0, 0, 9, 0, 0)}
                    max={new Date(0, 0, 0, 17, 0, 0)}
                    events={this.state.calendar.map(cal=> {
                        cal.title= 'no disponible';
                      cal.start = new Date(cal.start);
                      cal.end = new Date(cal.end);
                      return cal;
                      })}
                    defaultView={'work_week'}
                    views={['day', 'work_week']}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => console.log('no tienes permisos')}
                    onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
                    eventPropGetter={this.eventColors}
                    messages={{
                        next: "Proximo",
                        previous: "Aterior",
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Día"
                        }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>)
    );
  }
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
    getAgenda: () => dispatch(getAgenda()),
    newCalendar: data => dispatch(newCalendar(data)),
    updateEditedCalendar: data => dispatch(updateEditedCalendar(data)),
    removeControl: (id) =>(dispatch(removeControl(id))),
    goHome: ()=>(dispatch(push('/'))),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaSoloUsuario);