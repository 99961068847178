import React from 'react';
import { connect } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import moment from 'moment';

import { getVentas } from 'actions';


import classnames from 'classnames';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Table,
} from 'reactstrap';

class Ventas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ventas: '',
			msg: '',
		};
	}


	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.ventas !== this.props.ventas) {
			this.setState({
				ventas: this.props.ventas,
			});
		}
		if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	componentDidMount() {
	    this.props.getVentas();
	}


	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	editPlan = (id) => {
		console.log(`Editar ${id}`);
	};

	verPlan = (id) => {
		console.log(`Ver ${id}`);
	};

	removePlan = (id) => {
		console.log(`Borrar ${id}`);
	};

	listVentas = () => {
		const { ventas } = this.state;
			return (
				<tbody>
				{_.map(ventas , (x)=>(
					<tr key={x._id}>
					<td className="text-center">{x._id}</td>
					<td>{x.producto}</td>
					<td>{x?.userEmail || ''}</td>
					<td>${x.monto} </td>
					<td className="text-right">{x.plataforma}</td>
					<td>{x.payment_id}</td>
					<td className="text-right">{`${moment(x.createdAt).format('l')} - ${moment(x.createdAt).format('LT')}`}</td>
				</tr>
				))}
				</tbody>
			);
		};

    change = (e) => {
        console.log(e.target.value);
    }

	render() {
		console.log(this.state.ventas);
		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<div className="content">
					<Row>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<div className="tools float-right">
										<InputGroup
											className={classnames(this.state.buscarState, {
												'input-group-focus': this.state.buscarFocus,
											})}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="tim-icons icon-zoom-split" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												name="buscar"
												placeholder="Buscar..."
												type="text"
												onChange={(e) => this.change(e)}
												onFocus={(e) => this.setState({ buscarFocus: true })}
												onBlur={(e) => this.setState({ buscarFocus: false })}
											/>
										</InputGroup>
									</div>
									<CardTitle tag="h4" className="text-left">Listado Ventas</CardTitle>
								</CardHeader>
								<CardBody>
									<Table responsive style={{ overflow: 'none' }}>
										<thead className="text-primary">
											<tr>
												<th className="text-center">Producto id</th>
												<th className="text-center">Producto</th>
												<th className="text-center">Email Usuario</th>
												<th className="text-center">Monto</th>
												<th className="text-right">Plataforma</th>
												<th className="text-center">Transacción</th>
												<th className="text-right">Fecha</th>
											</tr>
										</thead>
										{this.listVentas()}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getVentas: () => dispatch(getVentas()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ventas);