import React from 'react';
import { connect } from 'react-redux';
import { getUserById, clearUsuario, getControllsByUserId, deleteControl } from 'actions';
import _ from 'lodash';
import Loader from 'assets/img/loadern.gif';
import { push, goBack } from 'react-router-redux';
import moment from 'moment';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// reactstrap components
import { Button, Card, CardBody, CardFooter, Container, Row, Col } from 'reactstrap';

const initialState = {};

class Controles extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.match.params)) {
			const { id } = this.props.match.params;
			if (id) {
				this.props.getControllsByUserId(id);
				return;
			}
		}

		if (!this.props.match.params.id) {
			this.props.clearUsuario();
			this.setState(initialState);
			return;
		}

		this.setState({
			selectedFiles: null,
			usuario: this.props.usuario,
		});
	}

	handlerNuevoControlOnline() {
		const { id } = this.props.match.params;
		this.props.newControl(id);
	}

	handlerNuevoControlPresencial() {
		const { id } = this.props.match.params;
		this.props.newControlPresencial(id);
	}

	handlerVerDetallesControlOnline = (id) => {
		console.log('detalles de control' + id);
		const { id: idUsuario } = this.props.match.params;
		this.props.editControl(id, idUsuario);
	};

	handlerVerDetallesControlOffline = (id) => {
		console.log('detalles de control' + id);
		const { id: idUsuario } = this.props.match.params;
		this.props.editControlOffline(id, idUsuario);
    };

    removeControl = (id) => {
		console.log(`Borrar ${id}`);
		this.props.deleteControl(id);
    };

	listControllsUser = () => {
		const controlls = this.props.controles;
		if (_.isEmpty(controlls)) {
			return;
		}
		const controllsItem = controlls?.map((control) => {
			const image = control?.avatar?.split(',');
			console.log(image);
			return (
				<div key={control.createdAt} style={{ width: '100%', padding: '5px' }}>
					<Card className="card-register card-black">
						<CardBody>
							<img
								alt={'Control Img'}
								src={!image || image[0] === "" ? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x280/nutricionconsciente-1607463588489.png' :`https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/${image[0]}` }
							/>
							<div style={{ display: 'flex', justifyContent: 'left', marginTop: '30px' }}>
								<ul style={{ width: '100%' }}>
									<li style={{ listStyle: 'none', textAlign: 'left' }}>
										{`Control: ${control.typo}`}
									</li>
									<li style={{ listStyle: 'none', textAlign: 'left' }}>
										{`Fecha: ${moment(control.createdAt).subtract(10, 'days').calendar()}`}
									</li>
									<li style={{ listStyle: 'none', textAlign: 'left' }}>
										{`Peso: ${control.peso} kg`}
									</li>
								</ul>
							</div>
						</CardBody>
						<CardFooter style={{ textAlign: 'center' }}>
							<Button
								className="btn-link btn-icon"
								color="success"
								size="sm"
								onClick={(e) => {
									control.typo === 'Online'
										? this.handlerVerDetallesControlOnline(control._id)
										: this.handlerVerDetallesControlOffline(control._id);
								}}
							>
								<i className="tim-icons icon-pencil" />
							</Button>
							<Button className="btn-link" color="warning" onClick={(e) => {this.props.gotoDetallesControles(control._id)}} size="sm">
								<i className="icon-line-eye" />
							</Button>
							<Button className="btn-link" color="danger" onClick={(e) => {this.removeControl(control._id)}} size="sm">
								<i className="icon-trash-alt1" />
							</Button>
						</CardFooter>
					</Card>
				</div>
			);
		});

		return controllsItem;
	};

	handlerGoBack() {
		this.props.goBack();
	}

	render() {
		const responsive = {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 5,
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 4,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
			},
			mobile: {
				breakpoint: { max: 664, min: 0 },
				items: 1,
			},
		};
		return this.props.loading ? (
			<div style={{ width: '100%', textAlign: 'center', color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
		) : (
			<>
				<Container>
					<Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '20px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: '#dadada' }}>
									Controles
								</h3>
							</div>
						</Col>
					</Row>

					<Row className="ml-auto mr-auto text-center">
						<Col className="mr-auto ml-auto" md="12" style={{ margin: '10px' }}></Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col className="mr-auto ml-auto" md="12">
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '300px' }}
								onClick={(e) => {
									this.handlerNuevoControlOnline();
								}}
								size="lg"
							>
								{'Crear nuevo control Online'}
							</Button>
							{this.props.user.type ==='admin' && <Button
								className="btn-round"
								color="warning"
								style={{ width: '300px' }}
								onClick={(e) => {
									this.handlerNuevoControlPresencial();
								}}
								size="lg"
							>
								{'Crear nuevo control Presencial'}
							</Button>}
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px', marginTop: '50px' }}>
					<Container>
						<Row className="ml-auto mr-auto text-center">
							<Col className="mr-auto ml-auto" md="12">
								<Row className="ml-auto mr-auto text-center">
									<Col md="12">
										<Carousel
											swipeable={true}
											draggable={true}
											responsive={responsive}
											ssr
											infinite={false}
											containerClass="first-carousel-container container"
											dotListClass="custom-dot-list-style"
										>
											{!_.isEmpty(this.props.controles) ? this.listControllsUser() : <div></div>}
										</Carousel>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newControl: (idUsuario) => dispatch(push(`/auth/nueva-ficha-control/${idUsuario}`)),
		newControlPresencial: (idUsuario) => dispatch(push(`/auth/nueva-ficha-control-presencial/${idUsuario}`)),
		getControllsByUserId: (id) => dispatch(getControllsByUserId(id)),
		editControl: (idControl, idUsuario) => dispatch(push(`/auth/editar-ficha-control/${idUsuario}/${idControl}`)),
		editControlOffline: (idControl, idUsuario) =>
			dispatch(push(`/auth/editar-ficha-control-offline/${idUsuario}/${idControl}`)),
        goBack: () => dispatch(goBack()),
        gotoDetallesControles:(id)=> dispatch(push(`/auth/detalles-controles/${id}/`)),
        deleteControl:(id) => dispatch(deleteControl(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Controles);
