import React from "react";
import GaleryImage from 'components/GaleryImage';

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";

class GaleryImages extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("gallery");
  }
  componentWillUnmount() {
    document.body.classList.toggle("gallery");
  }
  render() {
    return (
      <>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
              </Col>
            </Row>
            <Row>
            <Col md="12">
              <Card className="card-testimonial">
                <CardHeader className="card-header-avatar">
                    <img
                      alt="..."
                      src={require("assets/img/logo-blanco.png")}
                    />
                </CardHeader>
                <CardBody>
                  <div className="icon icon-primary">
                   <GaleryImage />
                  </div>
                </CardBody>
                <CardFooter>
                  <CardTitle tag="h4"></CardTitle>
                  <p className="category">@virtualacceso</p>
                </CardFooter>
              </Card>
            </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default GaleryImages;