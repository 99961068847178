export const actions = {
	IMAGE_NAME:"IMAGE_NAME",
	IMAGE_NAME_SUCCESS:"IMAGE_NAME_SUCCESS",
	IMAGE_NAME_ERROR:"IMAGE_NAME_ERROR",
	LOGIN: 'LOGIN',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	REGISTRAR_USUARIO_NUEVO:"REGISTRAR_USUARIO_NUEVO",
	REGISTRAR_USUARIO_NUEVO_SUCCESS:"REGISTRAR_USUARIO_NUEVO_SUCCESS",
	REGISTRAR_USUARIO_NUEVO_ERROR:"REGISTRAR_USUARIO_NUEVO_ERROR",
	REGISTRAR_USUARIO:"REGISTRAR_USUARIO",
	REGISTRAR_USUARIO_SUCCESS:"REGISTRAR_USUARIO_SUCCESS",
	REGISTRAR_USUARIO_ERROR:"REGISTRAR_USUARIO_ERROR",
	CLEAR_USUARIO:"CLEAR_USUARIO",
	USER_DATA:"USER_DATA",
	USER_DATA_SUCCESS:"USER_DATA_SUCCESS",
	USER_DATA_ERROR:"USER_DATA_ERROR",
	UPDATE_USER:"UPDATE_USER",
	UPDATE_USER_SUCCESS:"UPDATE_USER_SUCCESS",
	UPDATE_USER_ERROR:"UPDATE_USER_ERROR",
	DELETE_USER:"DELETE_USER",
	DELETE_USER_SUCCESS:"DELETE_USER_SUCCESS",
	DELETE_USER_ERROR:"DELETE_USER_ERROR",
	GET_MENSAJES:"GET_MENSAJES",
	GET_MENSAJES_SUCCESS:"GET_MENSAJES_SUCCESS",
	GET_MENSAJES_ERROR:"GET_MENSAJES_ERROR",
	SEND_MENSAJE:"SEND_MENSAJE",
	SEND_MENSAJE_SUCCESS:"SEND_MENSAJE_SUCCESS",
	SEND_MENSAJE_ERROR:"SEND_MENSAJE_ERROR",
	CHANGE_STATUS_MENSAJE:"CHANGE_STATUS_MENSAJE",
	CHANGE_STATUS_MENSAJE_SUCCESS:"CHANGE_STATUS_MENSAJE_SUCCESS",
	CHANGE_STATUS_MENSAJE_ERROR:"CHANGE_STATUS_MENSAJE_ERROR",
	GET_MENSAJES_ENVIADOS:"GET_MENSAJES_ENVIADOS",
	GET_MENSAJES_ENVIADOS_SUCCESS:"GET_MENSAJES_ENVIADOS_SUCCESS",
	GET_MENSAJES_ENVIADOS_ERROR:"GET_MENSAJES_ENVIADOS_ERROR",
	GET_USERS:"GET_USERS",
	GET_USER_BY_ID:"GET_USER_BY_ID",
	GET_USER_BY_ID_SUCCESS:"GET_USER_BY_ID_SUCCESS",
	GET_USER_BY_ID_ERROR:"GET_USER_BY_ID_ERROR",
	GET_USERS_SUCCESS:"GET_USERS_SUCCESS",
	GET_USERS_ERROR:"GET_USERS_ERROR",
	GET_PLANES:"GET_PLANES",
	GET_PLANES_SUCCESS:"GET_PLANES_SUCCESS",
	GET_PLANES_ERROR:"GET_PLANES_ERROR",
	GET_AGENDA:"GET_AGENDA",
	GET_AGENDA_SUCCESS:"GET_AGENDA_SUCCESS",
	GET_AGENDA_ERROR:"GET_AGENDA_ERROR",
	GET_RECETAS:"GET_RECETAS",
	GET_RECETAS_SUCCESS:"GET_RECETAS_SUCCESS",
	GET_RECETAS_ERROR:"GET_RECETAS_ERROR",
	GET_VENTAS:"GET_VENTAS",
	GET_VENTAS_SUCCESS:"GET_VENTAS_SUCCESS",
	GET_VENTAS_ERROR:"GET_VENTAS_ERROR",
	GO_EDIT_PROFILE:"GO_EDIT_PROFILE",
	GO_CONTROLES:'GO_CONTROLES',
	GO_CONTROLES_SUCCESS:'GO_CONTROLES_SUCCESS',
	GO_CONTROLES_ERROR:'GO_CONTROLES_ERROR',
	REGISTRAR_ANAMNESIS_NUEVO:'REGISTRAR_ANAMNESIS_NUEVO',
	REGISTRAR_ANAMNESIS_NUEVO_SUCCESS:'REGISTRAR_ANAMNESIS_NUEVO_SUCCESS',
	REGISTRAR_ANAMNESIS_NUEVO_ERROR:'REGISTRAR_ANAMNESIS_NUEVO_ERROR',
	GET_ANAMNESIS_BY_ID:'GET_ANAMNESIS_BY_ID',
	GET_ANAMNESIS_BY_ID_SUCCESS:'GET_ANAMNESIS_BY_ID_SUCCESS',
	GET_ANAMNESIS_BY_ID_ERROR:'GET_ANAMNESIS_BY_ID_ERROR',
	UPDATE_ANAMNESIS:'UPDATE_ANAMNESIS',
	UPDATE_ANAMNESIS_SUCCESS:'UPDATE_ANAMNESIS_SUCCESS',
	UPDATE_ANAMNESIS_ERROR:'UPDATE_ANAMNESIS_ERROR',
	NEW_PLAN:'NEW_PLAN',
	NEW_PLAN_SUCCESS:'NEW_PLAN_SUCCESS',
	NEW_PLAN_ERROR:'NEW_PLAN_ERROR',
	GET_PLAN_BY_ID:'GET_PLAN_BY_ID',
	GET_PLAN_BY_ID_SUCCESS:'GET_PLAN_BY_ID_SUCCESS',
	GET_PLAN_BY_ID_ERROR:'GET_PLAN_BY_ID_ERROR',
	UPDATE_PLAN:'UPDATE_PLAN',
	UPDATE_PLAN_SUCCESS:'UPDATE_PLAN_SUCCESS',
	UPDATE_PLAN_ERROR:'UPDATE_PLAN_ERROR',
	DELETE_PLAN:"DELETE_PLAN",
	DELETE_PLAN_SUCCESS:"DELETE_PLAN_SUCCESS",
	DELETE_PLAN_ERROR:"DELETE_PLAN_ERROR",
	GET_CONTROLLS_BY_USER_ID:"GET_CONTROLLS_BY_USER_ID",
	GET_CONTROLLS_BY_USER_ID_SUCCESS:"GET_CONTROLLS_BY_USER_ID_SUCCESS",
	GET_CONTROLLS_BY_USER_ID_ERROR:"GET_CONTROLLS_BY_USER_ID_ERROR",
	NEW_CONTROLL:"NEW_CONTROLL",
	NEW_CONTROLL_SUCCESS:"NEW_CONTROLL_SUCCESS",
	NEW_CONTROLL_ERROR:"NEW_CONTROLL_ERROR",
	UPDATE_CONTROLL:"UPDATE_CONTROLL",
	UPDATE_CONTROLL_SUCCESS:"UPDATE_CONTROLL_SUCCESS",
	UPDATE_CONTROLL_ERROR:"UPDATE_CONTROLL_ERROR",
	GET_CONTROLL_BY_ID:"GET_CONTROLL_BY_ID",
	GET_CONTROLL_BY_ID_SUCCESS:"GET_CONTROLL_BY_ID_SUCCESS",
	GET_CONTROLL_BY_ID_ERROR:"GET_CONTROLL_BY_ID_ERROR",
	DELETE_CONTROL:"DELETE_CONTROL",
	DELETE_CONTROL_SUCCESS:"DELETE_CONTROL_SUCCESS",
	DELETE_CONTROL_ERROR:'DELETE_CONTROL_ERROR',
	NEW_RECETA:'NEW_RECETA',
	NEW_RECETA_SUCCESS:'NEW_RECETA_SUCCESS',
	NEW_RECETA_ERROR:'NEW_RECETA_ERROR',
	GET_RECETA_BY_ID:'GET_RECETA_BY_ID',
	GET_RECETA_BY_ID_SUCCESS:'GET_RECETA_BY_ID_SUCCESS',
	GET_RECETA_BY_ID_ERROR:'GET_RECETA_BY_ID_ERROR',
	UPDATE_RECETA:'UPDATE_RECETA',
	UPDATE_RECETA_SUCCESS:'UPDATE_RECETA_SUCCESS',
	UPDATE_RECETA_ERROR:'UPDATE_RECETA_ERROR',
	DELETE_RECETA:'DELETE_RECETA',
	DELETE_RECETA_SUCCESS:'DELETE_RECETA_SUCCESS',
	DELETE_RECETA_ERROR:'DELETE_RECETA_ERROR',
	DELETE_TIP:'DELETE_TIP',
	DELETE_TIP_SUCCESS:'DELETE_TIP_SUCCESS',
	DELETE_TIP_ERROR:'DELETE_TIP_ERROR',
	UPDATE_TIP:'UPDATE_TIP',
	UPDATE_TIP_SUCCESS:'UPDATE_TIP_SUCCESS',
	UPDATE_TIP_ERROR:'UPDATE_TIP_ERROR',
	UPDATE_HOME_SLIDER:'UPDATE_HOME_SLIDER',
	UPDATE_HOME_SLIDER_SUCCESS:'UPDATE_HOME_SLIDER_SUCCESS',
	UPDATE_HOME_SLIDER_ERROR:'UPDATE_HOME_SLIDER_ERROR',
	GET_TIP_BY_ID:'GET_TIP_BY_ID',
	GET_TIP_BY_ID_SUCCESS:'GET_TIP_BY_ID_SUCCESS',
	GET_TIP_BY_ID_ERROR:'GET_TIP_BY_ID_ERROR',
	GET_TIPS:"GET_TIPS",
	GET_TIPS_SUCCESS:"GET_TIPS_SUCCESS",
	GET_TIPS_ERROR:"GET_TIPS_ERROR",
	GET_HOME_SLIDER:"GET_HOME_SLIDER",
	GET_HOME_SLIDER_SUCCESS:"GET_HOME_SLIDER_SUCCESS",
	GET_HOME_SLIDER_ERROR:"GET_HOME_SLIDER_ERROR",
	NEW_TIP:'NEW_TIP',
	NEW_TIP_SUCCESS:'NEW_TIP_SUCCESS',
	NEW_TIP_ERROR:'NEW_TIP_ERROR',
	NEW_HOME_SLIDER:'NEW_HOME_SLIDER',
	NEW_HOME_SLIDER_SUCCESS:'NEW_HOME_SLIDER_SUCCESS',
	NEW_HOME_SLIDER_ERROR:'NEW_HOME_SLIDER_ERROR',
	UPDATE_FILE:'UPDATE_FILE',
	UPDATE_FILE_SUCCESS:'UPDATE_FILE_SUCCESS',
	UPDATE_FILE_ERROR:'UPDATE_FILE_ERROR',
	NEW_FILE:'NEW_FILE',
	NEW_FILE_SUCCESS:'NEW_FILE_SUCCESS',
	NEW_FILE_ERROR:'NEW_FILE_ERROR',
	GET_FILE_BY_ID:'GET_FILE_BY_ID',
	GET_FILE_BY_ID_SUCCESS:'GET_FILE_BY_ID_SUCCESS',
	NEW_MERCADO_PAGO_SELL:'NEW_MERCADO_PAGO_SELL',
	NEW_MERCADO_PAGO_SELL_SUCCESS:'NEW_MERCADO_PAGO_SELL_SUCCESS',
	NEW_MERCADO_PAGO_SELL_ERROR:'NEW_MERCADO_PAGO_SELL_ERROR',
	GET_COMPRAS_BY_USER_ID:'GET_COMPRAS_BY_USER_ID',
	GET_COMPRAS_BY_USER_ID_SUCCESS:'GET_COMPRAS_BY_USER_ID_SUCCESS',
	GET_COMPRAS_BY_USER_ID_ERROR:'GET_COMPRAS_BY_USER_ID_ERROR',
	GET_HOME_SLIDER_MOBILE:'GET_HOME_SLIDER_MOBILE',
	GET_HOME_SLIDER_MOBILE_SUCCESS:'GET_HOME_SLIDER_MOBILE_SUCCESS',
	GET_HOME_SLIDER_MOBILE_ERROR:'GET_HOME_SLIDER_MOBILE_ERROR',
	NEW_HOME_MOBILE_SLIDER:'NEW_HOME_MOBILE_SLIDER',
	NEW_HOME_MOBILE_SLIDER_SUCCESS:'NEW_HOME_MOBILE_SLIDER_SUCCESSS',
	NEW_HOME_MOBILE_SLIDER_ERROR:'NEW_HOME_MOBILE_SLIDER_ERROR',
	UPDATE_HOME_MOBILE_SLIDER:'UPDATE_HOME_MOBILE_SLIDER',
	UPDATE_HOME_SLIDER_MOBILE_SUCCESS:'UPDATE_HOME_SLIDER_MOBILE_SUCCESS',
	UPDATE_HOME_MOBILE_SLIDER_ERROR:'UPDATE_HOME_MOBILE_SLIDER_ERROR',
	FORGOT_PASSWORD:'FORGOT_PASSWORD',
	FORGOT_PASSWORD_SUCCESS:'FORGOT_PASSWORD_SUCCESS',
	FORGOT_PASSWORD_ERROR:'FORGOT_PASSWORD_ERROR',
	NEW_PASSWORD:'NEW_PASSWORD',
	NEW_PASSWORD_SUCCESS:'NEW_PASSWORD_SUCCESS',
	NEW_PASSWORD_ERROR:'NEW_PASSWORD_ERROR',
	NEW_CALENDAR:'NEW_CALENDAR',
	NEW_CALENDAR_SUCCESS:'NEW_CALENDAR_SUCCESS',
	NEW_CALENDAR_ERROR:'NEW_CALENDAR_ERROR',
	UPDATE_CALENDAR:'UPDATE_CALENDAR',
	UPDATE_CALENDAR_SUCCESS:'UPDATE_CALENDAR_SUCCESS',
	UPDATE_CALENDAR_ERROR:'UPDATE_CALENDAR_ERROR',
	REMOVE_CONTROL:'REMOVE_CONTROL',
	REMOVE_CONTROL_SUCCESS:'REMOVE_CONTROL_SUCCESS',
	REMOVE_CONTROL_ERROR:'REMOVE_CONTROL_ERROR',
	ASSIGN_PLAN:'ASSIGN_PLAN',
	ASSIGN_PLAN_SUCCESS:'ASSIGN_PLAN_SUCCESS',
	ASSIGN_PLAN_ERROR:'ASSIGN_PLAN_ERROR',
	GET_PAGES:'GET_PAGES',
	GET_PAGES_SUCCESS:'GET_PAGES_SUCCESS',
	GET_PAGES_ERROR:'GET_PAGES_ERROR',
	NEW_PAGES:'NEW_PAGES',
	NEW_PAGES_SUCCESS:'NEW_PAGES_SUCCESS',
	NEW_PAGES_ERROR:'NEW_PAGES_ERROR',
	GET_PAGES_BY_ID:'GET_PAGES_BY_ID',
	GET_PAGES_BY_ID_SUCCESS:'GET_PAGES_BY_ID_SUCCESS',
	GET_PAGES_BY_ID_ERROR:'GET_PAGES_BY_ID_ERROR',
	UPDATE_PAGES:'UPDATE_PAGES',
	UPDATE_PAGES_SUCCESS:'UPDATE_PAGES_SUCCESS',
	UPDATE_PAGES_ERROR:'UPDATE_PAGES_ERROR',
	MANDO_MAIL:'MANDO_MAIL',
	MANDO_MAIL_SUCCESS:'MANDO_MAILS_SUCCESS',
	MANDO_MAIL_ERROR:'MANDO_MAIL_ERROR'
};