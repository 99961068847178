import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import AgendaUsuarios from 'components/AgendaUsuarios';
import AgendaSoloUsuario from 'components/Admin/AgendaSoloUsuario';


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

class TurnosPresenciales extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("turnos-presenciales-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("turnos-presenciales-page");
  }
  render() {
    const { user } = this.props;
    const token = user?._id;
    const control={
      title:'Agenda',
      description:'',
      type:'control',
      costPesos:1000,
      _id:'acsdffcontrol1231331123'
      };
    return (
      <>
        <div className="content">
          <Container>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#717171'}}>Agenda</h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Card className="card-success">
                    <CardBody className={"text-left"}>
                    <Col className="ml-auto mr-auto text-left" md="9">
                    </Col>
                    </CardBody>
                    <CardFooter className="text-center mb-3 mt-3">

                    {token ?
          <>
          {/* <ButtonMercadoPago productSelected={control}  user={user} typeProduct={"control"} /> */}
          <br />
          <br />
                <h4 className="title" style={{color:'#717171'}}> Eventos </h4>
                <AgendaSoloUsuario />
          </>
          :
          <>
          <p style={{color:'#717171'}} ></p>
          <Button
                                  style={{ marginTop: '15px', width: '220px' }}
                                  className="btn-round"
                                  color="success"
                                  href="/auth/registrarme"
                                  size="lg"
                              >
                                  Registrarme
                              </Button>
                              <AgendaUsuarios />
          </>}
                    </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	  mercadopago: (link) => dispatch(push(link)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnosPresenciales);