export const photos = [
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a2.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a3.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a4.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a5.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a6.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a7.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a8.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a9.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a10.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a11.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a12.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a13.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a14.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a15.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a16.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a17.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a18.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a19.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a20.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a21.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a22.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a23.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a24.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a25.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a26.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a27.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a28.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a29.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a30.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a31.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a32.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a33.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a34.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a35.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a36.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://appsuy.com/imagenes_premium_wraps/a37.jpg",
      width: 1,
      height: 1
    },
  ];