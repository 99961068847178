import React from "react";
import { Container, Row, Col,Button } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import logo from "assets/img/logo2.png";

class Footer extends React.Component {
  render() {
    return (
      <footer
      className={"footer" + (this.props.default ? " footer-default" : "")}
    >
      <Container fluid={true}>
      <Row md="9">
      {/* <Col style={{textAlign:'center', marginBottom:"30px"}} md="12">
         <img alt="#" src={logo} width="220px"></img>
      </Col> */}
      </Row>
      <Row md="12">
      
      <Col md="4">
            <p></p>
            {/* <Button
                                className="btn-icon btn-simple"
                                color="facebook"
                                href='https://www.facebook.com/'
                              >
                                <i className="fab fa-facebook-square" style={{fontSize:'35px'}}/>
                              </Button>
                              <Button
                                className="btn-icon btn-simple"
                                color="primary"
                                href='https://www.instagram.com/'
                              >
                                <i className="fab fa-instagram" style={{fontSize:'35px'}}/>
                              </Button> */}
          </Col>
        <Col md="4">
        {/* <h6>Sobre Virtual Acceso</h6>
          <div>
          <a href="/auth/politicas-de-privacidad" target="_self"> Politicas de Privacidad</a>
          </div>
          <div>
          <a href="/auth/terminos-y-condiciones" target="_self"> Terminos y condiciones</a>
          </div> */}
          </Col>
          <Col md="4">
         {/*  <h6>Preguntas Frecuentes </h6>
          <div>
          <a href="/preguntas-frecuentes" target="_self"> Ver listado de preguntas</a>
          </div>
          <div>
          <a href="/hacer-pregunta" target="_self"> hacer una pregunta</a>
          </div> */}
          </Col>
        </Row>
      </Container>
      <Container fluid={this.props.fluid ? true : false}>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          <a href="https://appsuy.com" target="_blank">
            AppsUY
          </a>{" "}
          for a better web.
        </div>
      </Container>
    </footer>

    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
