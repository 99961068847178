const config = {
    shortSiteTitle:"Virtual Acceso",
    siteTitle:"Virtual Acceso ",
    siteDescription:"Virtual Acceso ",
    siteImage:"",
    pathPrefix:"Virtual Acceso ",
    siteUrl:"",
    authorTwitterAccount:"",
    facebookAppId:"",
}

export default config;