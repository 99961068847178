import React from "react";

import { connect } from 'react-redux';
import { getUserById, clearUsuario } from 'actions';
import { goBack } from 'react-router-redux';
import { updateEditedPages, getPagesById, newPages } from 'actions';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button
} from "reactstrap";

class Quiensoy extends React.Component {
  constructor(props) {
		super(props);
		this.state = {
			pages: {
				title:'',
                subtitle:'',
                description:'',
                contactNumber:'',
                contactEmail:'',
                contactAddress:'',
                contactHorarios:'',
                linkFacebook:'',
                linkInstagram:'',
                linkYoutube:'',
                linkTweeter:'',
                editorState:{},
                avatar:'',
			},
			pagesData: [],
			imagenes: '',
			msg: '',
			editMode: false,
            filesToSend:'',
            selectedFiles:'',
		};
  }
  
  static getDerivedStateFromProps(props, state) {
		if (props.pages !== state.pages) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.pages !== this.props.pages) {
			this.setState({
				pages: this.props.pages,
				pagesData: this.props.pages,
				editMode: true,
				selectedFiles: null,
			});
		}
	}

  componentDidMount() {
		const id = '601753ad2a87944de9ba3a00';
		if (id) {
			this.props.getPagesById(id);
			return;
		}
  }
  

  componentWillUnmount() {
    document.body.classList.toggle("quien-soy-page");
  }
  render() {
    const images = this.state?.pages?.avatar?.split(',');
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: 1,
        },
    };
    return (
      <>
      { !this.props.loading && <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
              </Col>
            </Row>
            <Row style={{ marginBottom: '50px', width: '100%', textAlign: 'center' }}>
						<Col md="12">
							<Card className="card-testimonial">
								<CardHeader className="card-header-avatar">
									<img alt="..." src={require('assets/img/logo-blanco.png')} />
								</CardHeader>
								<CardBody>
								<Row md="12">
										<Col md="12">
										<h3>¿Eres organizador, productor o artista?</h3>
										<h6 style={{ color: '#0072c0' }} >Comunícate con nosotros para obtener tu página web del evento 100% administrable, y en minutos!</h6>
                    <Button
						href={'/auth/contacto'}
						className="btn-round btn-just-icon"
                        color="info"
						style={{marginLeft:'auto', marginRight:'auto'}}
					>
						Contactar
					</Button>
</Col>
</Row>
									<Row md="12">
										<Col md="12">
											<i
												className="icon-line-video"
												style={{ fontSize: '50px', color: '#0072c0' }}
											/>
											<h6>Funcionamiento de VirtualAcceso</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
												Al Ingresar a nuestra HomePage como invitado tendrás acceso a eventos
												públicos /privados / gratuitos y/o de pago.
												<br />
												Si eres organizador, productor o artista, podrás acceder al panel de
												administración, para la creación de una página web para tu evento 100 %
												administrable con una de nuestras plantillas.
												<br />
												Contáctanos y cuéntanos sobre tu evento para brindarte acceso de
												administración! <br />
												El link de tu evento podrá estar alojado en nuestro dominio, o podrás
												obtener un dominio 100% personalizado con el nombre que elijas (ej:
												tueventocorporativo.com).
												<br />
												Aparecer en nuestra cartelera de eventos (opcional), te servirá como
												disparador de nuevos invitados para tu evento!
											</p>
										</Col>
									</Row>
									<Row md="12">
										<Col md="6">
											<i className="icon-like" style={{ fontSize: '50px', color: '#0072c0', marginTop:'50px'}} />
											<h6>¿Por qué VirtualAcceso?</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
											Nuestro equipo cuenta con una larga experiencia humana y técnica en eventos corporativos.  Conocemos lo que hacemos, conocemos el mercado, y te brindamos la seguridad para que tu evento salga como lo has planeado.
											</p>
										</Col>
										<Col md="6">
											<i className="icon-eye2" style={{ fontSize: '50px', color: '#0072c0', marginTop:'50px' }} />
											<h6>Emisión del evento</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
											La emisión del evento podrá ser en nuestra plataforma, sin límites de
												usuarios, o cualquier red social y/o plataformas disponibles en el
												mercado online (zoom, youtube, Facebook, Instagram).<br />Si lo que deseas es
												mostrar al mundo tus conocimientos, brindar clases, venta de cursos y/o
												entrenamientos, también tenemos una solución para ti!
											</p>
										</Col>
									</Row>
									<Row md="12">
										<Col md="6">
											<i className="icon-unlocked2" style={{ fontSize: '50px', color: '#0072c0' }} />
											<h6>Nuestra Seguridad</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
											Los datos y contraseñas de nuestros usuarios se almacenan tokenizados, utilizando nuestra propia plataforma de backend y frontend, en nuestros servidores privados.<br />No utilizamos plataformas con bajo perfil de seguridad como WIX, WordPress, etc.
											</p>
										</Col>
										<Col md="6">
											<i
												className="icon-wallet1"
												style={{ fontSize: '50px', color: '#0072c0' }}
											/>
											<h6>Pasarela de pagos</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
												Pasarela de Pagos Podrás gestionar la tarifa de ingreso de los
												participantes, o donaciones, mediante el cobro online con tarjetas, o
												efectivo, directo a tu cuenta personal/empresarial de MERCADOPAGOS y/o
												PAYPAL.
											</p>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<CardTitle tag="h4"></CardTitle>
									<p className="category">@virtualacceso</p>
								</CardFooter>
							</Card>
						</Col>
</Row>
          </Container>
        </div>}
      </>
    );
  }
}



const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newPages: (data) => dispatch(newPages(data)),
		updateEditedPages: (data, id) => dispatch(updateEditedPages(data, id)),
		getPagesById: (id) => dispatch(getPagesById(id)),
		goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Quiensoy);
