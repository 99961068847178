import { put } from 'redux-saga/effects';
//import { goBack } from 'react-router-redux';
import * as actions from '../actions/';
import * as client from '../client/';
import { errorMessage } from '../errors.js';
import { goBack, push } from 'react-router-redux';

export function* login(payload) {
	try {
		const result = yield client.login(payload);
		yield put(actions.loginSuccess(result));
		localStorage.setItem('token', result.data.token);
		yield put(push('/'));
	} catch (error) {
		yield put(actions.loginError(errorMessage(error)));
	}
}

export function* mandoMail(data) {
	try {
		const result = yield client.mandoMail(data);
		yield put(actions.mandoMailSuccess(result));
		
	} catch (error) {
		yield put(actions.mandoMailError(errorMessage(error)));
	}
}

export function* forgotPassword(payload) {
	try {
		const result = yield client.forgotPassword(payload);
		yield put(actions.forgotPasswordSuccess(result));
		yield client.resetPasswordUser(payload);
	} catch (error) {
		yield put(actions.forgotPasswordError(errorMessage(error)));
	}
}

export function* newPassword(payload) {
	try {
		const result = yield client.newPassword(payload);
		yield put(actions.newPasswordSuccess(result));
		yield put(push('/auth/entrar'));
	} catch (error) {
		yield put(actions.newPasswordError(errorMessage(error)));
	}
}

export function* getAgenda() {
	try {
		const result = yield client.getAgenda();
		yield put(actions.getAgendaSuccess(result));
	} catch (error) {
		yield put(actions.getAgendaError(errorMessage(error)));
	}
}

export function* getPages() {
	try {
		const result = yield client.getPages();
		yield put(actions.getPagesSuccess(result));
	} catch (error) {
		yield put(actions.getPagesError(errorMessage(error)));
	}
}

export function* getRecetas() {
	try {
		const result = yield client.getRecetas();
		yield put(actions.getRecetasSuccess(result));
	} catch (error) {
		yield put(actions.getRecetasError(errorMessage(error)));
	}
}

export function* getPlanes() {
	try {
		const result = yield client.getPlanes();
		yield put(actions.getPlanesSuccess(result));
	} catch (error) {
		yield put(actions.getPlanesError(errorMessage(error)));
	}
}

export function* getTips() {
	try {
		const result = yield client.getTips();
		yield put(actions.getTipsSuccess(result));
	} catch (error) {
		yield put(actions.getTipsError(errorMessage(error)));
	}
}

export function* getHomeSlider() {
	try {
		const result = yield client.getHomeSlider();
		yield put(actions.getHomeSliderSuccess(result));
	} catch (error) {
		yield put(actions.getHomeSliderError(errorMessage(error)));
	}
}

export function* getHomeSliderMobile() {
	try {
		const result = yield client.getHomeSliderMobile();
		yield put(actions.getHomeSliderMobileSuccess(result));
	} catch (error) {
		yield put(actions.getHomeSliderMobileError(errorMessage(error)));
	}
}

export function* getAnamnesisById(data) {
	try {
		const result = yield client.getAnamnesisById(data.id);
		yield put(actions.getAnamnesisByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getAnamnesisByIdError(errorMessage(error)));
	}
}

export function* registrarAnamnesisNuevo(data) {
	try {
		const result = yield client.registrarAnamnesisNuevo(data.data);
		yield put(actions.registrarAnamnesisNuevoSuccess(result));
		yield put(goBack());
	} catch (error) {
		yield put(actions.registrarAnamnesisNuevoError(errorMessage(error)));
	}
}
export function* getFileById(data) {
	try {
		const result = yield client.getFileById(data.id);
		yield put(actions.getFileByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getFileByIdError(errorMessage(error)));
	}
}

export function* getComprasByUserId(data) {
	try {
		const result = yield client.getComprasByUserId(data.id);
		yield put(actions.getComprasByUserIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getComprasByUserIdError(errorMessage(error)));
	}
}

export function* getVentas() {
	try {
		const result = yield client.getVentas();
		yield put(actions.getVentasSuccess(result.data));
	} catch (error) {
		yield put(actions.getVentasError(errorMessage(error)));
	}
}

export function* mercadoPagoBuy(data) {
	try {
		const result = yield client.mercadoPagoBuy(data.payload);
		yield put(actions.mercadoPagoBuySuccess(result));
		yield (window.location.href = result.data.init_point);
	} catch (error) {
		yield put(actions.mercadoPagoBuyError(errorMessage(error)));
	}
}

export function* newFile(data) {
	try {
		const result = yield client.newFile(data.payload);
		yield put(actions.newFileSuccess(result));
	} catch (error) {
		yield put(actions.newFileError(errorMessage(error)));
	}
}

export function* updateEditedFile(data) {
	try {
		const result = yield client.updateEditedFile(data.data, data.id);
		yield put(actions.updateEditedFileSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedFileError(errorMessage(error)));
	}
}

export function* newPlan(data) {
	try {
		const result = yield client.newPlan(data.payload);
		yield put(actions.newPlanSuccess(result));
		yield put(goBack());
	} catch (error) {
		yield put(actions.newPlanError(errorMessage(error)));
	}
}

export function* newTip(data) {
	try {
		const result = yield client.newTip(data.payload);
		yield put(actions.newTipSuccess(result));
	} catch (error) {
		yield put(actions.newTipError(errorMessage(error)));
	}
}

export function* newHomeSlider(data) {
	try {
		const result = yield client.newHomeSlider(data.payload);
		yield put(actions.newHomeSliderSuccess(result));
	} catch (error) {
		yield put(actions.newHomeSliderError(errorMessage(error)));
	}
}

export function* newHomeSliderMobile(data) {
	try {
		const result = yield client.newHomeSliderMobile(data.payload);
		yield put(actions.newHomeSliderMobileSuccess(result));
	} catch (error) {
		yield put(actions.newHomeSliderMobileError(errorMessage(error)));
	}
}

export function* updateEditedPlan(data) {
	try {
		const result = yield client.updateEditedPlan(data.data, data.id);
		yield put(actions.updateEditedPlanSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedPlanError(errorMessage(error)));
	}
}

export function* updateEditedHomeSlider(data) {
	try {
		const result = yield client.updateEditedHomeSlider(data.data, data.id);
		yield put(actions.updateEditedHomeSliderSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedHomeSliderError(errorMessage(error)));
	}
}

export function* updateEditedHomeSliderMobile(data) {
	try {
		const result = yield client.updateEditedHomeSliderMobile(data.data, data.id);
		yield put(actions.updateEditedHomeSliderMobileSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedHomeSliderMobileError(errorMessage(error)));
	}
}

export function* updateEditedTip(data) {
	try {
		const result = yield client.updateEditedTip(data.data, data.id);
		yield put(actions.updateEditedTipSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedTipError(errorMessage(error)));
	}
}

export function* getPlanById(data) {
	try {
		const result = yield client.getPlanById(data.id);
		yield put(actions.getPlanByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getPlanByIdError(errorMessage(error)));
	}
}

export function* getTipById(data) {
	try {
		const result = yield client.getTipById(data.id);
		yield put(actions.getTipByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getTipByIdError(errorMessage(error)));
	}
}

export function* newPages(data) {
	try {
		const result = yield client.newPages(data.payload);
		yield put(actions.newPagesSuccess(result));
	} catch (error) {
		yield put(actions.newPagesError(errorMessage(error)));
	}
}

export function* newReceta(data) {
	try {
		const result = yield client.newReceta(data.payload);
		yield put(actions.newRecetaSuccess(result));
	} catch (error) {
		yield put(actions.newRecetaError(errorMessage(error)));
	}
}

export function* updateEditedPages(data) {
	try {
		const result = yield client.updateEditedPages(data.data, data.id);
		console.log(result);
		yield put(actions.updateEditedPagesSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedPagesError(errorMessage(error)));
	}
}

export function* updateEditedReceta(data) {
	try {
		const result = yield client.updateEditedReceta(data.data, data.id);
		console.log(result);
		yield put(actions.updateEditedRecetaSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedRecetaError(errorMessage(error)));
	}
}

export function* getPagesById(data) {
	try {
		const result = yield client.getPagesById(data.id);
		yield put(actions.getPagesByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getPagesByIdError(errorMessage(error)));
	}
}

export function* getRecetaById(data) {
	try {
		const result = yield client.getRecetaById(data.id);
		yield put(actions.getRecetaByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getRecetaByIdError(errorMessage(error)));
	}
}

export function* updateEditedAnamnesis(data) {
	try {
		const result = yield client.updateEditedAnamnesis(data.data, data.id);
		yield put(actions.updateEditedAnamnesisSuccess(result.data));
		yield put(goBack());
	} catch (error) {
		yield put(actions.updateEditedAnamnesisError(errorMessage(error)));
	}
}

export function* getUsers() {
	try {
		const result = yield client.getUsers();
		yield put(actions.getUsersSuccess(result));
	} catch (error) {
		yield put(actions.getUsersError(errorMessage(error)));
	}
}

export function* getUserById(data) {
	try {
		const result = yield client.getUserById(data.id);
		console.log(result.data);
		yield put(actions.getUserByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getUserByIdError(errorMessage(error)));
	}
}

export function* gotoControles({ payload }) {
	try {
		const result = yield client.getControllsByUserId(payload._id);
		yield put(actions.gotoControlesSuccess(result.data));
	} catch (error) {
		yield put(actions.gotoControlesError(errorMessage(error)));
	}
}

export function* getControllsByUserId({ payload }) {
	try {
		const result = yield client.getControllsByUserId(payload);
		yield put(actions.getControllsByUserIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getControllsByUserIdError(errorMessage(error)));
	}
}

export function* newControl(data) {
	try {
		const result = yield client.newControl(data.payload);
		yield put(actions.newControlSuccess(result));
	} catch (error) {
		yield put(actions.newControlError(errorMessage(error)));
	}
}

export function* newCalendar(data) {
	try {
		const result = yield client.newCalendar(data.payload);
		yield put(actions.newCalendarSuccess(result));
	} catch (error) {
		yield put(actions.newCalendarError(errorMessage(error)));
	}
}

export function* AssignPlan(data) {
	try {
		const result = yield client.AssignPlan(data.payload);
		yield put(actions.AssignPlanSuccess(result));
	} catch (error) {
		yield put(actions.AssignPlanError(errorMessage(error)));
	}
}

export function* updateEditedCalendar(data) {
	try {
		const result = yield client.updateEditedCalendar(data.data, data.id);
		yield put(actions.updateEditedCalendarSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedCalendarError(errorMessage(error)));
	}
}

export function* updateEditedControl(data) {
	try {
		const result = yield client.updateEditedControl(data.data, data.id);
		yield put(actions.updateEditedControlSuccess(result.data));
	} catch (error) {
		yield put(actions.updateEditedControlError(errorMessage(error)));
	}
}

export function* getControlById(data) {
	try {
		const result = yield client.getControlById(data.id);
		yield put(actions.getControlByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.getControlByIdError(errorMessage(error)));
	}
}

export function* deleteUser(data) {
	try {
		const result = yield client.deleteUser(data.id);
		yield put(actions.deleteUserSuccess(result));
	} catch (error) {
		yield put(actions.deleteUserError(errorMessage(error)));
	}
}

export function* removeControl(data) {
	try {
		const result = yield client.removeControl(data.id);
		yield put(actions.removeControlSuccess(result));
	} catch (error) {
		yield put(actions.removeControlError(errorMessage(error)));
	}
}

export function* deletePlan(data) {
	try {
		const result = yield client.deletePlan(data.id);
		yield put(actions.deletePlanSuccess(result));
	} catch (error) {
		yield put(actions.deletePlanError(errorMessage(error)));
	}
}

export function* deleteReceta(data) {
	try {
		const result = yield client.deleteReceta(data.id);
		yield put(actions.deleteRecetaSuccess(result));
	} catch (error) {
		yield put(actions.deleteRecetaError(errorMessage(error)));
	}
}

export function* deleteTip(data) {
	try {
		const result = yield client.deleteTip(data.id);
		yield put(actions.deleteTipSuccess(result));
	} catch (error) {
		yield put(actions.deleteTipError(errorMessage(error)));
	}
}

export function* deleteControl(data) {
	try {
		const result = yield client.deleteControl(data.id);
		yield put(actions.deleteControlSuccess(result));
	} catch (error) {
		yield put(actions.deleteControlError(errorMessage(error)));
	}
}

export function* registrarUsuarioNuevo(data) {
	try {
		console.log(data);
		const result = yield client.registrarUsuarioNuevo(data.data);
		yield put(actions.registrarUsuarioNuevoSuccess(result));
	} catch (error) {
		yield put(actions.registrarUsuarioNuevoError(errorMessage(error)));
	}
}

export function* userData() {
	try {
		const result = yield client.userData();
		yield put(actions.userDataSuccess(result));
	} catch (error) {
		yield put(actions.userDataError(errorMessage(error)));
	}
}

export function* regitrarUsuario(payload) {
	try {
		const result = yield client.registrarUsuario(payload);
		yield put(actions.registrarUsuarioSuccess(result));
		localStorage.setItem('token', result.data.token);
		yield put(push('/'));
		yield client.sendMailRegistroUsuario({
			payload: payload.payload,
		});
		yield client.sendMailNewUserToAdmin({
			payload: payload.payload,
			token: result.data.token,
		});
	} catch (error) {
		yield put(actions.registrarUsuarioError(errorMessage(error)));
	}
}

export function* updateUser(data) {
	try {
		const result = yield client.updateUser(data.data, data.id);
		yield put(actions.updateUserSuccess(result.data));
	} catch (error) {
		yield put(actions.updateUserError(errorMessage(error)));
	}
}

export function* changeStatusMensaje(data) {
	try {
		const result = yield client.changeStatusMensaje(data);
		yield put(actions.changeStatusMensajeSuccess(result));
	} catch (error) {
		yield put(actions.changeStatusMensajeError(errorMessage(error)));
	}
}

export function* sendMensaje(data) {
	try {
		const result = yield client.sendMensaje(data);
		yield put(actions.sendMensajeSuccess(result));
	} catch (error) {
		yield put(actions.sendMensajeError(errorMessage(error)));
	}
}

export function* getMensajesEnviados(x) {
	try {
		const result = yield client.getMensajesEnviados(x.x);
		yield put(actions.getMensajesEnviadosSuccess(result.data));
	} catch (error) {
		yield put(actions.getMensajesEnviadosError(errorMessage(error)));
	}
}

export function* getMensajes(x) {
	try {
		const result = yield client.getMensajes(x.x);
		yield put(actions.getMensajesSuccess(result.data));
	} catch (error) {
		yield put(actions.getMensajesError(errorMessage(error)));
	}
}

export function* imageNames(x) {
	try {
		yield put(actions.imageNamesSuccess(x.x.image));
	} catch (error) {
		yield put(actions.imageNamesError(errorMessage(error)));
	}
}
