/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import Seo from '../../components/Seo';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Zoom } from 'react-slideshow-image';
import { connect } from 'react-redux';
import { getPlanes, getRecetas, getHomeSlider, getHomeSliderMobile, getTips } from 'actions';
import { push } from 'react-router-redux';
import _ from 'lodash';

import Plan from 'components/Plan';
import Receta from 'components/Receta';
import Tip from 'components/Tip';
import { isMobile } from 'react-device-detect';

import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, CardTitle,Button } from 'reactstrap';

const deviceType = 'mobile';
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 664, min: 0 },
		items: 1,
	},
};
const zoomInProperties = {
	indicators: true,
	scale: 1.4,
	width: '100%',
};

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			homeSlider: {
				title: '',
				description: '',
				avatar: '',
			},
			homeSliderMobile: {
				title: '',
				description: '',
				avatar: '',
			},
			tips: '',
			imagenes: '',
			msg: '',
			alert: null,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.homeSlider !== state.homeSlider || props.homeSliderMobile !== state.homeSliderMobile) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.homeSlider !== this.props.homeSlider) {
			this.setState({
				homeSlider: this.props.homeSlider[0],
				homeSliderData: this.props.homeSlider[0],
			});
		}
		if (prevProps.homeSliderMobile !== this.props.homeSliderMobile) {
			this.setState({
				homeSliderMobile: this.props.homeSliderMobile[0],
				homeSliderMobileData: this.props.homeSliderMobile[0],
			});
		}
	}

	componentDidMount() {
		document.body.classList.toggle('pricing-page');
		this.props.getPlanes();
		this.props.getRecetas();
		this.props.getHomeSlider();
		this.props.getHomeSliderMobile();
		this.props.getTips();
	}
	componentWillUnmount() {
		document.body.classList.toggle('pricing-page');
	}

	handlerVerDetallesControl = () => {
		console.log('verMas');
	};

	render() {
		const { user } = this.props;
		const { planes, recetas, tips } = this.props;
		const images = this.state?.homeSlider?.avatar !== '' ? _.split(this.state.homeSlider.avatar, ',') : [];
		const imagesMobile =
			this.state?.homeSliderMobile?.avatar !== '' ? _.split(this.state.homeSliderMobile.avatar, ',') : [];

		return (
			<>
				<Seo />
				<div className="content">
					<Container>
						<Row md="12">
										<Col md="12" style={{textAlign:'center'}}>
										<h3>¿Eres organizador, productor o artista? {'  '}<Button
						href={'/auth/sobre-nosotros'}
						className="btn-round btn-just-icon"
                        color="info"
						style={{marginLeft:'auto', marginRight:'auto'}}
					>
						Saber Más
					</Button></h3>
										
</Col>
</Row>
						
						<Row style={{ marginBottom: '50px', width: '100%', textAlign: 'center' }}>
							<Col md="12">
								<h3>Cartelera de Eventos</h3>
								<h6 style={{ color: '#0072c0' }}>¿ Eres invitado buscando un evento para participar? <br />Puedes revisar nuestra cartelera!</h6>
								{
									<Carousel
										swipeable={true}
										draggable={false}
										responsive={responsive}
										ssr
										autoPlay
										autoPlaySpeed={4000}
										infinite={true}
										containerClass="first-carousel-container container"
										dotListClass="custom-dot-list-style"
										removeArrowOnDeviceType={['tablet', 'mobile']}
									>
										{recetas ? (
											recetas?.map((receta) => (
												<div>
													<Receta user={user} recetaDetail={receta} />{' '}
												</div>
											))
										) : (
											<div />
										)}
									</Carousel>
								}
							</Col>
						</Row>
						<Row style={{ marginBottom: '50px', width: '100%', textAlign: 'center' }}>
						<Col md="12">
							<Card className="card-testimonial">
								<CardHeader className="card-header-avatar">
									<img alt="..." src={require('assets/img/logo-blanco.png')} />
								</CardHeader>
								<CardBody>
								<Row md="12">
										<Col md="12">
										<h3>¿Eres organizador, productor o artista?</h3>
										<h6 style={{ color: '#0072c0' }} >Comunícate con nosotros para obtener tu página web del evento 100% administrable, y en minutos!</h6>
										<Button
						href={'/auth/contacto'}
						className="btn-round btn-just-icon"
                        color="info"
						style={{marginLeft:'auto', marginRight:'auto'}}
					>
						Contactar
					</Button>
</Col>
</Row>
									<Row md="12">
										<Col md="12">
											<i
												className="icon-line-video"
												style={{ fontSize: '50px', color: '#0072c0' }}
											/>
											<h6>Funcionamiento de VirtualAcceso</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
												Al Ingresar a nuestra HomePage como invitado tendrás acceso a eventos
												públicos /privados / gratuitos y/o de pago.
												<br />
												Si eres organizador, productor o artista, podrás acceder al panel de
												administración, para la creación de una página web para tu evento 100 %
												administrable con una de nuestras plantillas.
												<br />
												Contáctanos y cuéntanos sobre tu evento para brindarte acceso de
												administración! <br />
												El link de tu evento podrá estar alojado en nuestro dominio, o podrás
												obtener un dominio 100% personalizado con el nombre que elijas (ej:
												tueventocorporativo.com).
												<br />
												Aparecer en nuestra cartelera de eventos (opcional), te servirá como
												disparador de nuevos invitados para tu evento!
											</p>
										</Col>
									</Row>
									<Row md="12">
										<Col md="6">
											<i className="icon-like" style={{ fontSize: '50px', color: '#0072c0', marginTop:'50px'}} />
											<h6>¿Por qué VirtualAcceso?</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
											Nuestro equipo cuenta con una larga experiencia humana y técnica en eventos corporativos.  Conocemos lo que hacemos, conocemos el mercado, y te brindamos la seguridad para que tu evento salga como lo has planeado.
											</p>
										</Col>
										<Col md="6">
											<i className="icon-eye2" style={{ fontSize: '50px', color: '#0072c0', marginTop:'50px' }} />
											<h6>Emisión del evento</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
											La emisión del evento podrá ser en nuestra plataforma, sin límites de
												usuarios, o cualquier red social y/o plataformas disponibles en el
												mercado online (zoom, youtube, Facebook, Instagram).<br />Si lo que deseas es
												mostrar al mundo tus conocimientos, brindar clases, venta de cursos y/o
												entrenamientos, también tenemos una solución para ti!
											</p>
										</Col>
									</Row>
									<Row md="12">
										<Col md="6">
											<i className="icon-unlocked2" style={{ fontSize: '50px', color: '#0072c0' }} />
											<h6>Nuestra Seguridad</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
											Los datos y contraseñas de nuestros usuarios se almacenan tokenizados, utilizando nuestra propia plataforma de backend y frontend, en nuestros servidores privados.<br />No utilizamos plataformas con bajo perfil de seguridad como WIX, WordPress, etc.
											</p>
										</Col>
										<Col md="6">
											<i
												className="icon-wallet1"
												style={{ fontSize: '50px', color: '#0072c0' }}
											/>
											<h6>Pasarela de pagos</h6>
											<br />
											<p style={{ paddingLeft: '70px', paddingRight: '70px' }}>
												Pasarela de Pagos Podrás gestionar la tarifa de ingreso de los
												participantes, o donaciones, mediante el cobro online con tarjetas, o
												efectivo, directo a tu cuenta personal/empresarial de MERCADOPAGOS y/o
												PAYPAL.
											</p>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<CardTitle tag="h4"></CardTitle>
									<p className="category">@virtualacceso</p>
								</CardFooter>
							</Card>
						</Col>
</Row>
						<Row
							style={{
								display: 'flex',
								justifyContent: 'center',
								paddingTop: '20px',
								marginLeft: 'auto',
								marginRight: 'auto',
								textAlign: 'center',
							}}
						>
							<Col md="12">
								<h3
									className="info-title"
									style={{ color: '#dadada', marginBottom: '10px', marginTop: '5px' }}
								></h3>
								<h4
									className="info-title"
									style={{ color: '#dadada', marginBottom: '10px', marginTop: '5px' }}
								></h4>
							</Col>
						</Row>
						<Row style={{ marginBottom: '50px', width: '100%' }}>
							<Col md="12">
								<h3
									className="info-title"
									style={{
										color: '#dadada',
										marginBottom: '10px',
										marginTop: '40px',
										textAlign: 'center',
									}}
								></h3>
								<h4
									className="info-title"
									style={{
										color: '#dadada',
										marginBottom: '10px',
										marginTop: '10px',
										textAlign: 'center',
									}}
								>
									{' '}
								</h4>
								{/* <Carousel
									swipeable={true}
									draggable={false}
									responsive={responsive}
									ssr
									autoPlay
									autoPlaySpeed={4000}
									infinite={true}
									containerClass="first-carousel-container container"
									dotListClass="custom-dot-list-style"
									removeArrowOnDeviceType={['tablet', 'mobile']}
								>
									{planes ? (
										planes?.map((plan) => (
											<div>
												<Plan user={user} planDetail={plan} />{' '}
											</div>
										))
									) : (
										<div />
									)}
								</Carousel> */}
							</Col>
						</Row>

						<Row
							style={{
								display: 'flex',
								justifyContent: 'center',
								paddingTop: '10px',
								marginLeft: 'auto',
								marginRight: 'auto',
								textAlign: 'center',
							}}
						>
							<Col md="12">
								<h3
									className="info-title"
									style={{
										color: '#dadada',
										marginBottom: '10px',
										marginTop: '40px',
										textAlign: 'center',
									}}
								></h3>
								<h4
									// eslint-disable-next-line jsx-a11y/heading-has-content
									className="info-title"
									style={{
										color: '#dadada',
										marginBottom: '10px',
										marginTop: '10px',
										textAlign: 'center',
									}}
								></h4>
							</Col>
						</Row>

						<Row style={{ marginBottom: '50px' }}>
							<Col md="12">
								{/* <Carousel
									swipeable={true}
									draggable={false}
									responsive={responsive}
									ssr
									autoPlay
									autoPlaySpeed={4000}
									infinite={true}
									containerClass="first-carousel-container container"
									deviceType={deviceType}
									dotListClass="custom-dot-list-style"
									removeArrowOnDeviceType={['tablet', 'mobile']}
								>
									{!_.isEmpty(tips) ? (
										tips?.map((tip) => (
											<div>
												<Tip user={user} tipDetail={tip} />{' '}
											</div>
										))
									) : (
										<div />
									)}
								</Carousel> */}
							</Col>
						</Row>
					</Container>
				</div>{' '}
				<style jsx>{`
					.each-slide > div {
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: cover;
						width: 100%;
					}

					.each-slide span {
						font-size: 20px;
						background: #efefef;
						text-align: center;
					}
				`}</style>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		mercadopago: (link) => dispatch(push(link)),
		getPlanes: () => dispatch(getPlanes()),
		getRecetas: () => dispatch(getRecetas()),
		getHomeSlider: () => dispatch(getHomeSlider()),
		getHomeSliderMobile: () => dispatch(getHomeSliderMobile()),
		getTips: () => dispatch(getTips()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
