import React from 'react';

import { Button, Card, CardBody, CardFooter, CardImg} from 'reactstrap';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { split,truncate } from 'lodash';
import ButtonMercadoPago from 'components/ButtonMercadoPago';

const Receta = (props) => {
    const { user, recetaDetail } = props;
	const token = user._id;
	const images = recetaDetail.avatar !== '' ? split(recetaDetail.avatar, ',') : [];

	const handlerComprar = (Link) => {
		window.location.assign(Link);
    };
    
    const handlerVerDetallesPlanes = id => {
        props.irDetallesReceta(id);
      }

	return (
        <div style={{width:'90%', margin:'10px'}}>
		<Card className="card-pricing card-success" style={{borderRadius:'20px', minHeight:'500px'}}>
		<CardImg alt="..." src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/280x250/${images[0]}`} />
			<CardBody>
				<div className="card-prices">
					<h3 className="text-on-front">
						{/* {recetaDetail.costPesos ? <span>$</span> : <span></span>}
						{recetaDetail.costPesos} */}
					</h3>
					{/* <h5 className="text-on-back" style={{fontSize:'90px',height:'100px'}}>{recetaDetail.costPesos}</h5> */}
					<h4 className='warning' style={{textAlign:'center'}}>{recetaDetail.subtitle}</h4>
				</div>
				
				<div style={{textAlign:'center'}}>
				<p className='text-muted' style={{textAlign:'center'}}>{truncate(recetaDetail.title, { length: 80, separator: ' ...' }) }</p>
			
				</div>
			</CardBody>
			<CardFooter className="text-center mb-1 mt-1">
			<Button
						href={recetaDetail.videoPromo}
						className="btn-round btn-just-icon"
                        color="info"
						style={{marginLeft:'auto', marginRight:'auto'}}
					>
						Ir al Evento
					</Button>
				{/* {token ? <ButtonMercadoPago productSelected={recetaDetail}  user={user} typeProduct={"receta"} />
				: (
					<>
						<p className='text-muted'>Para poder comprar necesitamos que te registres</p>
						<Button
							style={{ marginTop: '15px', width: '220px' }}
							className="btn-round"
                            color="warning"
							href="/auth/registrarme"
							size="lg"
						>
							Registrarse
						</Button>
					</>
				)} */}
			</CardFooter>
		</Card>
        </div>
	);
};


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
      irDetallesReceta:(id) => dispatch(push(`detalle-evento/${id}`))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Receta);