import React from "react";

import { connect } from 'react-redux';
import { getUserById, clearUsuario } from 'actions';
import { goBack } from 'react-router-redux';
import { updateEditedPages, getPagesById, newPages, mandoMail } from 'actions';



import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  Input

} from "reactstrap";

class Contacto extends React.Component {
  constructor(props) {
		super(props);
		this.state = {
      contacto:{
        email:'',
        subject:'',
        message:'',
      },
			pages: {
				title:'',
                subtitle:'',
                description:'',
                contactNumber:'',
                contactEmail:'',
                contactAddress:'',
                contactHorarios:'',
                linkFacebook:'',
                linkInstagram:'',
                linkYoutube:'',
                linkTweeter:'',
                editorState:{},
			},
			pagesData: [],
			imagenes: '',
			msg: '',
			editMode: false,
            filesToSend:'',
            selectedFiles:'',
		};
  }
  
  static getDerivedStateFromProps(props, state) {
		if (props.pages !== state.pages) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.pages !== this.props.pages) {
			this.setState({
				pages: this.props.pages,
				pagesData: this.props.pages,
				editMode: true,
				selectedFiles: null,
			});
		}
	}

  handleInput = (event) => {
		const { contacto } = this.state;
		contacto[event.target.name] = event.target.value;
		this.setState({ contacto });
	};

  handlerSend = (e) => {
    this.props.mandoMail(this.state.contacto);
  }

  componentDidMount() {
		const id = '601753ad2a87944de9ba3a00';
		if (id) {
			this.props.getPagesById(id);
			return;
		}
  }
  

  componentWillUnmount() {
    document.body.classList.toggle("quien-soy-page");
  }
  render() {
    return (
      <>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
              </Col>
            </Row>
            <Row>
            <Col md="12">
              <Card className="card-testimonial">
                <CardHeader className="card-header-avatar">
                    <img
                      alt="..."
                      src={require("assets/img/logo-blanco.png")}
                    />
                </CardHeader>
                <CardBody>

                <Row md="12">
                   
                        <Col md="8" style={{textAlign:'left', padding:'30px'}}>
                           {/* <div style={{width:'100%'}} dangerouslySetInnerHTML={ {__html:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.0718097830127!2d-54.95773958477448!3d-34.904647380382656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95751a847fb6fd85%3A0xf465ece18197864!2sRoman%20Bergalli%20%26%20Rincon%2C%2020000%20Maldonado%2C%20Departamento%20de%20Maldonado!5e0!3m2!1ses-419!2suy!4v1612141575321!5m2!1ses-419!2suy" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>' }} /> */}
                           <h3>Formulario Contacto</h3>
													<Input
														placeholder="Email"
														name="email"
														type="text"
														value={this.state.contacto.email}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
                          <label></label>
													<Input
														placeholder="Motivo"
														name="subject"
														type="text"
														value={this.state.contacto.subject}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
                          <label></label>
													<Input
														placeholder="Mensaje"
														name="message"
                            cols="20"
                            style={{height: '150px', color:'#fff', padding:'10px',marginBottom:'30px'}}
														rows="12"
														type="textarea"
														value={this.state.contacto.message}
														onChange={this.handleInput}
													/>
{this.props.message && <p style={{color:'#88e288', margin:'10px'}}>{this.props.message}</p>}
                          <Button
											className="btn-round"
											color="info"
											style={{ width: '150px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Enviar
										</Button>

                    <div className="icon icon-primary"></div>
                        </Col>


                  <Col md="4" style={{textAlign:'left', padding:'70px'}}>
                    <div className="icon icon-primary">
                    
                    </div>
                    
                  <div>
                     <h5><i className="icon-line-mail" style={{ color:'#fff', fontSize:'20px'}} /> {'  '}{`${this.props.pages.contactEmail}`}</h5>
                  </div>
                  <div>
                     <h5><i className="icon-phone1" style={{ color:'#fff', fontSize:'20px'}} />{'  '}{`${this.props.pages.contactNumber}`}</h5>
                  </div>
                  <div>
                     <h5><i className="icon-warehouse" style={{ color:'#fff', fontSize:'20px'}} />{'  '}{`${this.props.pages.contactAddress}`}</h5>
                  </div>

                  <div>
                     <h5><i className="icon-clock2" style={{ color:'#fff', fontSize:'20px'}} /> {'  '} Horarios :{`${this.props.pages.contactHorarios}`}</h5>
                  </div>
                         
                        </Col>
                        </Row>


                  <br />
                </CardBody>
                <CardFooter>
                  <CardTitle tag="h4"></CardTitle>
                  <p className="category">@virtualacceso</p>
                </CardFooter>
              </Card>
            </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}



const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newPages: (data) => dispatch(newPages(data)),
		updateEditedPages: (data, id) => dispatch(updateEditedPages(data, id)),
		getPagesById: (id) => dispatch(getPagesById(id)),
		goBack: () => dispatch(goBack()),
    mandoMail:(data)=>dispatch(mandoMail(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacto);
