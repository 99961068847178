import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import  * as actions from "../../actions/";
import AdminNavBar from "./AdminNavbar";
import _ from 'lodash';

import logo from "assets/img/logo.png";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
		const token = localStorage.getItem('token');
		const { user } = this.props;

		if(_.isEmpty(user) && token){
			this.props.getUserData();
		}

  }
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-black";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  goToUrl = url => {
		window.open(url, '_blank');
  };

  navbarcomun  = () =>{
	return(
    <div className={classnames('navbar-absolute')}>
      <div style={{width:'100%',height:'40px', float:'right'}}>
						{/* <NavLink to="/auth/entrar" className="nav-link" style={{float:"right"}}>
							<i className="icon-signin" /> Login Usuario
						</NavLink>
						<NavLink to="/auth/registrarme" className="nav-link" style={{float:"right"}}>
							<i className="icon-clipboard-check" /> Registrarme
						</NavLink> */}
					</div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%',}}>
              <img alt="logo" src={logo} width="500px" height="150px"/>
            </div>
<Navbar className={classnames( this.state.color)} expand="lg">
  <Container fluid style={{ marginTop: '0px' }}>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>

          <Collapse isOpen={this.state.collapseOpen} onClick={this.state.collapseOpen ? this.toggleCollapse : undefined } navbar>
            <Nav navbar style={{marginLeft:'auto' , marginRight:'auto'}}>
            <NavItem>
            <NavLink to="/auth/home" className="nav-link" style={{color:'#FFF',textAlign:'center'}}>
                  Home
                </NavLink>
                </NavItem>
                <NavItem>
                {/* <NavLink to="/auth/sobre-nosotros" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Sobre nosotros
                </NavLink> */}
              </NavItem>
              <NavItem>
                <NavLink to="/auth/servicios" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Eventos
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/auth/galeria" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Galería
                </NavLink>
              </NavItem> */}
              <NavItem>
                
              </NavItem>
              <NavItem>
                <NavLink to="/auth/contacto" className="nav-link" style={{color:'#FFF', textAlign:'center'}}>
                  Contacto
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      </div>
  );
	}

  showBar= () => {
	const { user } = this.props;
	const bar = _.isEmpty(user) ? this.navbarcomun() : <AdminNavBar user = {this.props.user}/>;
	return bar;
	}

  render() {
    return  this.showBar();
  }
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
		getUserData: ()=>{
			dispatch(actions.userData());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavbar);
