import React from 'react';
import { connect } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import {getUsers, gotoEditProfile, gotoControles, deleteUser} from 'actions';
import { push } from 'react-router-redux';

import classnames from 'classnames';
import Loader from 'assets/img/loadern.gif';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
} from 'reactstrap';

class Usuarios extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			buscar: '',
			orderBy: 'desc',
		};
	}

	componentDidMount() {
		document.body.classList.toggle('usuarios-component');
		this.props.getUsers();
	}
	componentWillUnmount() {
		document.body.classList.toggle('usuarios-component');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	editUser = (x) => {
		this.props.gotoEditProfile(x);
		this.props.goToEdit(x._id);
	};

	controlesUser = (x) => {
		this.props.gotoControles(x);
		this.props.goToControles(x._id);
	};

	verUser = (id) => {
		console.log(`Ver ${id}`);
	};

	removeUser = (id) => {
		console.log(`Borrar ${id}`);
		this.props.deleteUser(id);
	};


	listUsuarios = () => {
	const { users } = this.props;

	const filterByTextNombre = _.filter(users, x =>
		this.reemplazarAcentos(x.name.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const filterByTextEmail = _.filter(users, x =>
		this.reemplazarAcentos(x.email.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const listFiltered = _.uniq((_.concat(filterByTextNombre, filterByTextEmail)));


	const flOrder = this.state.orderByStateValue
			? _.orderBy(listFiltered, 'state', this.state.orderByState)
			: listFiltered;

	const lista = _.map(flOrder , (x)=>{

		const images = x.avatar !== '' ? _.split(x.avatar, ',') : [];
		let oldImages = null;

		const imgList = _.map(images, x => {
			if (x !== '' && x !== 'undefined' ) {
				return x;
			}});
		oldImages = _.compact(imgList);

		return (
			<tr key={x._id}>
				<td className="text-center">
					<div className="photo">
						<img alt="..." src={!_.isEmpty(oldImages)  ? `https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/${oldImages[0]}` : "https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png"} />
					</div>
				</td>
				<td>{x.name}</td>
				<td>{x.email}</td>
				<td className="text-center">{x.activePlan || "none"}</td>
				<td className="text-right">{x.celPhone || "none"}</td>
				<td className="text-right">{x.state ? "Activo" : "Desactivo"}</td>
				<td className="text-right">
				<Button
						className="btn-link btn-icon"
						color="success"
						id="tooltip324367706"
						size="sm"
						onClick={() => this.editUser(x)}
					>
						<i className="tim-icons icon-pencil" />
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip324367706">
						Editar
					</UncontrolledTooltip>

					<Button
						className="btn-link"
						color="success"
						id="tooltip974171204"
						onClick={() => this.verUser(x._id)}
						size="sm"
					>
						<i className="tim-icons icon-single-02" />
					</Button>

					<UncontrolledTooltip delay={0} target="tooltip974171204">
					Ir al perfli
					</UncontrolledTooltip>
					<Button
						className="btn-link"
						color="danger"
						id="tooltip974171201"
						onClick={() => this.removeUser(x._id)}
						size="sm"
					>
						<i className="tim-icons icon-simple-remove" />
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip974171201">
						Eliminar
					</UncontrolledTooltip>
				</td>
			</tr>
		);
	});
		return (
            <tbody>
			{lista}
            </tbody>
		);
    };
    
    change = (e) => {
	  this.setState({buscar:e.target.value})
	}
	
	handlerStatus=(value)=>{
		if (value) {
			this.setState({ ...this.state, orderByState: 'desc', orderByStateValue: true });
		} else {
			this.setState({ ...this.state, orderByState: '', orderByStateValue: false });
		}
	}

	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};

	render() {
		const { loading } = this.props;
		return (
			loading ? <div><img src={Loader} width="100px" alt=""/>loading...</div>
				:
				(
					<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>

				<div className="content">
					<Row>
						<Col md="12" className="ml-auto mr-auto text-left">
							<Button
								style={{ margin: '30px', width: '200px' }}
								className="btn-round"
								color="success"
								onClick={() => {this.props.nuevoUsuario(); }}
								size="lg"
							>
							<i className="tim-icons icon-simple-add" /> {" "} Nuevo Usuario
							</Button>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<div className="tools float-right">
										<InputGroup
											className={classnames(this.state.buscarState, {
												'input-group-focus': this.state.buscarFocus,
											})}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="tim-icons icon-zoom-split" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												name="buscar"
												placeholder="Buscar..."
												type="text"
												onChange={(e) => this.change(e)}
												onFocus={(e) => this.setState({ buscarFocus: true })}
												onBlur={(e) => this.setState({ buscarFocus: false })}
											/>
										</InputGroup>
									</div>
									<CardTitle tag="h4" className="text-left" >Listado de Usuarios</CardTitle>
								</CardHeader>
								<CardBody>
									<Table responsive style={{ overflow: 'none' }}>
										<thead className="text-primary">
											<tr>
												<th className="text-center">#</th>
												<th>Nombre</th>
												<th>Email</th>
												<th className="text-center">Plan</th>
												<th className="text-right">Cel</th>
												<th className="text-right">Estado</th>
												<th className="text-right">Acciones</th>
											</tr>
										</thead>
										{this.listUsuarios()}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				</>
			)
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: () => dispatch(getUsers()),
		gotoEditProfile:(usuario) => dispatch(gotoEditProfile(usuario)),
		goToEdit: (id) =>(dispatch(push(`/auth/usuario/${id}`))),
		gotoControles:(usuario) => dispatch(gotoControles(usuario)),
		goToControles: (id) =>(dispatch(push(`/auth/controles/${id}`))),
		nuevoUsuario:()=>(dispatch(push('/auth/nuevo-usuario'))),
		deleteUser:(id)=>(dispatch(deleteUser(id))),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
