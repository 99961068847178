import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { getTipById } from 'actions';
import YouTube from 'react-youtube';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  CardTitle, ListGroup
} from "reactstrap";



const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

class DetallesTips extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			tip: {
				title: '',
        description: '',
        editorState:{},
				type: '',
				file: '',
				items: '',
				avatar: '',
				nota: '',
				video: '',
				videoPromo: '',
				premium: false,
				createdAt: '',
			},
			tipData: [],
			imagenes: '',
			msg: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
		if (props.tip !== state.tip) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.tip !== this.props.tip) {
			this.setState({
				tip: this.props.tip,
				tipData: this.props.tip,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getTipById(id);
			return;
		}
    }
    
    
    handlerComprar (Link){
		window.location.assign(Link);
	};
	
	handlerGoBack() {
		this.props.goBack();
	}

  render() {
    const { user } = this.props;
    const { tip } = this.state;
    const token = user && user._id;
    const images = tip?.avatar.split(',');
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: 1,
        },
    };
    return (
      <>
        <div className="content">
          <Container>
		  <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="success"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#717171'}}>Detalles Tip</h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="9">
              <Card className="card-success">
              <CardBody>
				<CardTitle tag="h1" style={{ textAlign: 'center' }}>
					{tip.title}{' '}
				</CardTitle>
                {tip.videoPromo && tip.videoPromo.length > 3 &&
                    <YouTube videoId={tip.videoPromo} opts={opts} onReady={this._onReady} />
                }
                {tip.video && tip.video.length > 3 &&
                    <YouTube videoId={tip.video} opts={opts} onReady={this._onReady} />
                }
                <ListGroup>
                <Carousel
											swipeable={true}
											draggable={true}
											responsive={responsive}
											ssr
											infinite={false}
											containerClass="first-carousel-container container"
											dotListClass="custom-dot-list-style"
										>
                    {images.map((image)=>(
                        <div>
                            <img alt={"imagen Control"} src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/250x280/${image}`} width={'100%'} />
                        </div>
                        ))}
                    </Carousel>
                </ListGroup>
                
				<ListGroup>
				</ListGroup>
        <br/>
						<br/>
                <div dangerouslySetInnerHTML={{__html:tip.editorState}} />
						
						<br/>
						<br/>
						<br/>

			</CardBody>
                    <CardFooter className="text-center mb-3 mt-3">

                    </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
         
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	  getTipById: (id) => dispatch(getTipById(id)),
	  goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesTips);