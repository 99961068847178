import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { getRecetaById } from 'actions';
import YouTube from 'react-youtube';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AgendaSoloUsuario from 'components/Admin/AgendaSoloUsuario'
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";


const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

class AgendarControlUsuario extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            agenda:{},
			msg: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
		if (props.agenda !== state.agenda) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.agenda !== this.props.agenda) {
			this.setState({
			});
		}
	}

	componentDidMount() {
	   const { idUsuario } = this.props.match.params;
    }
    
    
    handlerComprar (Link){
		window.location.assign(Link);
	};
	
	handlerGoBack() {
		this.props.goBack();
	}

  render() {
    const { user } = this.props;
    const token = user && user._id;
    const tokenPosta = localStorage.getItem("token");
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: 1,
        },
    };
    return (
    
      <>
        { tokenPosta && (<div className="content">
          <Container>
		  <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="success"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#717171'}}>Muchas Gracias por tu compra! </h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
                <h4 className="title" style={{color:'#717171'}}>Selecciona un dia y una hora en el calendario</h4>
                <AgendaSoloUsuario />
              </Col>
            </Row>
            
          </Container>
        </div>)}
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
	  goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendarControlUsuario);