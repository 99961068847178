import React from 'react';
import { connect } from 'react-redux';
import { getUserById, clearUsuario } from 'actions';
import _ from 'lodash';
import Loader from 'assets/img/loadern.gif';
import { goBack } from 'react-router-redux';
import { updateEditedPlan, getPlanById, newPlan } from 'actions';

import './styles.css';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

// Import the Slate editor factory.

import Select from 'react-select';

// reactstrap components
import { Button, Card, CardBody, CardFooter, Form, Input, Container, Row, Col, Label } from 'reactstrap';

class Planes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			plan: {
				userId: '',
				title: '',
				description: '',
				editorState: {},
				type: '',
				anamnesis: '',
				time: '',
				queries: '',
				typeQueries: '',
				file: '',
				items: '',
				avatar: '',
				costDolars: '',
				costPesos: '',
				linktoPayPaypal: '',
				linktoPayMercadoLibre: '',
				nota: '',
				video: '',
				videoPromo: '',
				premium: false,
				createdAt: '',
			},
			planData: [],
			imagenes: '',
			msg: '',
			editMode: false,
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-plan-component');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.plan !== state.plan) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.plan !== this.props.plan) {
			this.setState({
				plan: this.props.plan,
				planData: this.props.plan,
				editMode: true,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getPlanById(id);
			return;
		}
	}

	handlerSelect = (value, type) => {
		const toChange = value.value;
		const { plan } = this.state;
		plan[type] = toChange;
		this.setState({ ...this.state, plan });
	};

	handleInput = (event) => {
		const { plan } = this.state;
		plan[event.target.name] = event.target.value;
		this.setState({ plan });
	};

	handleCheck = (event) => {
		const { plan } = this.state;
		plan[event.target.name] = !plan[event.target.name];
		this.setState({ plan });
	};

	handlerSend = () => {
		const data = this.state.plan;
		const idPlan = this.state.plan._id;
		const id = !_.isEmpty(this.props.usuario) ? this.props.usuario._id : this.props.match.params.id;
		if (idPlan) {
			this.props.updateEditedPlan(data, idPlan);
		} else {
			data.userId = id;
			this.props.newPlan(data);
		}
	};

	handleInputEditorState = (editorState) => {
		const { plan } = this.state;
		plan.editorState = editorState;
		this.setState({ plan });
	};

	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b></b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handlerGoBack() {
		this.props.goBack();
	}

	render() {
		const { editorState } = this.state.plan;

		return this.props.loading || !this.state.plan ? (
			<div style={{ width: '100%', textAlign: 'center', color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
		) : (
			<>
				<Container>
					<Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="success"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: '#dadada' }}>
									{this.state.editMode ? 'Editar plan' : 'Crear nuevo plan'}
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md="12">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Row>
											<Col className="mr-auto ml-auto" md="9" style={{ padding: '20px' }}></Col>
										</Row>
										<Form className="form">
											<Row md="12">
												<Col className="mr-auto ml-auto" md="12">
													<label>Titulo</label>
													<Input
														placeholder=""
														name="title"
														type="text"
														value={this.state.plan.title || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
													<label> Detalles del Plan </label>
													<SunEditor
														lang="es"
														onChange={this.handleInputEditorState}
														setContents={editorState}
														setOptions={{
															height: 400,
															buttonList: [
																[
																	'fontColor',
																	'hiliteColor',
																	'bold',
																	'underline',
																	'strike',
																	'subscript',
																	'superscript',
																	'align',
																	'font',
																	'fontSize',
																	'formatBlock',
																	'hiliteColor',
																	'horizontalRule',
																	'lineHeight',
																	'list',
																	'paragraphStyle',
																	'table',
																	'template',
																	'textStyle',
																],
															],
															colorList: [
																[
																	'#ccc',
																	'#dedede',
																	'#000',
																	'OrangeRed',
																	'Orange',
																	'RoyalBlue',
																	'SaddleBrown',
																],
															], // Or Array of button list, eg. [['font', 'align'], ['image']]
															// Other option
														}}
														placeholder="Detalles del plan..."
														enableToolbar={true}
														style={{ backgroundColor: '#000' }}
													/>
													<br />
													<br />
													<label>Tipo de Plan</label>

													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="type"
														value={{
															value: this.state.plan.type,
															label: this.state.plan.type,
														}}
														onChange={(value) => this.handlerSelect(value, 'type')}
														options={[
															{ value: 'Online', label: 'Online' },
															{ value: 'Descenso de peso', label: 'Descenso de peso' },
															{ value: 'Personalizado', label: 'Personalizado' },
														]}
														placeholder="Frecuencia"
													/>

													<label>Tipo de Anamnesis</label>

													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="anamnesis"
														value={{
															value: this.state.plan.anamnesis,
															label: this.state.plan.anamnesis,
														}}
														onChange={(value) => this.handlerSelect(value, 'anamnesis')}
														options={[
															{ value: 'Simple', label: 'Simple' },
															{ value: 'Extendido', label: 'Extendido' },
															{ value: 'No tiene', label: 'No tiene' },
														]}
														placeholder="Frecuencia"
													/>

													<label>Duración en Meses</label>
													<Input
														placeholder=""
														name="time"
														type="number"
														min="0"
														value={this.state.plan.time || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
													<label>Cantidad de controles</label>
													<Input
														placeholder=""
														name="queries"
														type="number"
														min="0"
														value={this.state.plan.queries || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
													<label>Tipo de Control</label>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="typeQueries"
														value={{
															value: this.state.plan.typeQueries,
															label: this.state.plan.typeQueries,
														}}
														onChange={(value) => this.handlerSelect(value, 'typeQueries')}
														options={[
															{ value: 'Controles Online', label: 'Controles Online' },
															{
																value: 'Controles Presenciales',
																label: 'Controles Presenciales',
															},
														]}
														placeholder=""
													/>
													
													<label>Video</label>
													<Input
														placeholder="Link del video"
														name="video"
														type="text"
														value={this.state.plan.video || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>

													<label>Video Promoción</label>
													<Input
														placeholder="Link del video"
														name="videoPromo"
														type="text"
														value={this.state.plan.videoPromo || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
													<div style={{ margin: '15px' }}>
														<Label check style={{ margin: '10px' }}>
															<Input
																type="checkbox"
																name="premium"
																checked={this.state.plan.premium || false}
																onChange={this.handleCheck}
															/>
															Es premium
														</Label>
													</div>

													<label>Costo en Dolares </label>
													<Input
														placeholder=""
														name="costDolars"
														type="number"
														min="0"
														value={this.state.plan.costDolars || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>

													<label>Costo en Pesos UY </label>
													<Input
														placeholder=""
														name="costPesos"
														type="number"
														min="0"
														value={this.state.plan.costPesos || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>

													
												</Col>
											</Row>
										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="success"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Guardar Plan
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newPlan: (data) => dispatch(newPlan(data)),
		updateEditedPlan: (data, id) => dispatch(updateEditedPlan(data, id)),
		getPlanById: (id) => dispatch(getPlanById(id)),
		goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Planes);
