import React from "react";
import { connect } from 'react-redux';
// react component used to create a calendar with events on it
import { Calendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar

import NotificationAlert from 'react-notification-alert';
import moment from "moment";

import { Card, CardBody, Row, Col } from "reactstrap";

import _ from 'lodash';
import {getAgenda, newCalendar, updateEditedCalendar, removeControl} from 'actions';

import Select from 'react-select';

import Loader from 'assets/img/loadern.gif';
import ReactBSAlert from 'react-bootstrap-sweetalert';


import {
  Form,
  Input,
	Button
} from 'reactstrap';


require('moment/locale/es.js');
const localizer = momentLocalizer(moment);

class AgendaUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendar:[],
      alert: null,
      evento:[{
        title:'',
        email:'',
        color:'',
        description:''
      },],
      msg: '',
			buscar: '',
    };
  }


	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.calendar !== this.props.calendar) {
			this.setState({
				calendar: this.props.calendar,
			});
    }
    if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	componentDidMount() {
    this.props.getAgenda();
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
  };

  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };


  render() {
    const { loading } = this.props;
		return (
			loading ? <div><img src={Loader} width="100px" alt=""/>loading...</div>
				:
				(
      <>
      <div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-calendar">
                <CardBody>
                  <Calendar
                    culture='es'
                    localizer={localizer}
                    step={60}
                    timeslots={1}
                    min={new Date(0, 0, 0, 9, 0, 0)}
                    max={new Date(0, 0, 0, 17, 0, 0)}
                    events={this.state.calendar.map(cal => {
                      cal.title ='No disponible'
                      cal.start = new Date(cal.start);
                      cal.end = new Date(cal.end);
                      return cal;
                      })}
                    defaultView={'work_week'}
                    views={['day', 'work_week']}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => console.log('no tienes permiso')}
                    onSelectSlot={slotInfo => console.log('no tienes permiso')}
                    eventPropGetter={this.eventColors}
                    messages={{
                        next: "Proximo",
                        previous: "Aterior",
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Día"
                        }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>)
    );
  }
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
    getAgenda: () => dispatch(getAgenda()),
    newCalendar: data => dispatch(newCalendar(data)),
    updateEditedCalendar: data => dispatch(updateEditedCalendar(data)),
    removeControl: (id) =>(dispatch(removeControl(id))),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaUsuarios);

