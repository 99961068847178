import { actions } from '../constants';

export const getPagesById = (id) => ({
	type: actions.GET_PAGES_BY_ID,
	id,
});

export const getPagesByIdSuccess = (data) => ({
	type: actions.GET_PAGES_BY_ID_SUCCESS,
	data,
});

export const getPagesByIdError = (data) => ({
	type: actions.GET_PAGES_BY_ID_ERROR,
	data,
});

export const mandoMail = (id) => ({
	type: actions.MANDO_MAIL,
	id,
});

export const mandoMailSuccess = (data) => ({
	type: actions.MANDO_MAIL_SUCCESS,
	data,
});



export const mandoMailError = (data) => ({
	type: actions.MANDO_MAIL_ERROR,
	data,
});

export const updateEditedPages = (data, id) => ({
	type: actions.UPDATE_PAGES,
	data,
	id,
});

export const updateEditedPagesSuccess = (data) => ({
	type: actions.UPDATE_PAGES_SUCCESS,
	data,
});

export const updateEditedPagesError = (data) => ({
	type: actions.UPDATE_PAGES_ERROR,
	data,
});


export const getPages = (payload) => ({
	type: actions.GET_PAGES,
	payload,
});

export const getPagesSuccess = (payload) => ({
	type: actions.GET_PAGES_SUCCESS,
	payload,
});

export const getPagesError = (payload) => ({
	type: actions.GET_PAGES_ERROR,
	payload,
});

export const newPages = (payload) => ({
	type: actions.NEW_PAGES,
	payload,
});

export const newPagesSuccess = (payload) => ({
	type: actions.NEW_PAGES_SUCCESS,
	payload,
});

export const newPagesError = (payload) => ({
	type: actions.NEW_PAGES_ERROR,
	payload,
});


export const registrarAnamnesisNuevo = (data) => ({
	type: actions.REGISTRAR_ANAMNESIS_NUEVO,
	data,
});

export const registrarAnamnesisNuevoSuccess = (payload) => ({
	type: actions.REGISTRAR_ANAMNESIS_NUEVO_SUCCESS,
	payload,
});

export const registrarAnamnesisNuevoError = (payload) => ({
	type: actions.REGISTRAR_ANAMNESIS_NUEVO_ERROR,
	payload,
});

export const getAnamnesisById = (id) => ({
	type: actions.GET_ANAMNESIS_BY_ID,
	id,
});

export const getAnamnesisByIdSuccess = (data) => ({
	type: actions.GET_ANAMNESIS_BY_ID_SUCCESS,
	data,
});

export const getAnamnesisByIdError = (data) => ({
	type: actions.GET_ANAMNESIS_BY_ID_ERROR,
	data,
});

export const updateEditedAnamnesis = (data, id) => ({
	type: actions.UPDATE_ANAMNESIS,
	data,
	id,
});

export const updateEditedAnamnesisSuccess = (data) => ({
	type: actions.UPDATE_ANAMNESIS_SUCCESS,
	data,
});

export const updateEditedAnamnesisError = (data) => ({
	type: actions.UPDATE_ANAMNESIS_ERROR,
	data,
});

export const updateUser = (data, id) => ({
	type: actions.UPDATE_USER,
	data,
	id,
});

export const updateUserSuccess = (data) => ({
	type: actions.UPDATE_USER_SUCCESS,
	data,
});

export const updateUserError = (data) => ({
	type: actions.UPDATE_USER_ERROR,
	data,
});

export const getUserById = (id) => ({
	type: actions.GET_USER_BY_ID,
	id,
});

export const getUserByIdSuccess = (data) => ({
	type: actions.GET_USER_BY_ID_SUCCESS,
	data,
});

export const getUserByIdError = (data) => ({
	type: actions.GET_USER_BY_ID_ERROR,
	data,
});

//Mensajes

export const getMensajesEnviados = (x) => ({
	type: actions.GET_MENSAJES_ENVIADOS,
	x,
});

export const getMensajesEnviadosSuccess = (payload) => ({
	type: actions.GET_MENSAJES_ENVIADOS_SUCCESS,
	payload,
});

export const getMensajesEnviadosError = (payload) => ({
	type: actions.GET_MENSAJES_ENVIADOS_ERROR,
	payload,
});

export const changeStatusMensaje = (x) => ({
	type: actions.CHANGE_STATUS_MENSAJE,
	x,
});

export const changeStatusMensajeSuccess = (payload) => ({
	type: actions.CHANGE_STATUS_MENSAJE_SUCCESS,
	payload,
});
export const changeStatusMensajeError = (payload) => ({
	type: actions.CHANGE_STATUS_MENSAJE_ERROR,
	payload,
});

export const getMensajes = (x) => ({
	type: actions.GET_MENSAJES,
	x,
});

export const getMensajesSuccess = (payload) => ({
	type: actions.GET_MENSAJES_SUCCESS,
	payload,
});

export const getMensajesError = (payload) => ({
	type: actions.GET_MENSAJES_ERROR,
	payload,
});

export const sendMensaje = (data) => ({
	type: actions.SEND_MENSAJE,
	data,
});

export const sendMensajeSuccess = (payload) => ({
	type: actions.SEND_MENSAJE_SUCCESS,
	payload,
});

export const sendMensajeError = (payload) => ({
	type: actions.SEND_MENSAJE_ERROR,
	payload,
});

//userData
export const userData = () => ({
	type: actions.USER_DATA,
});

export const userDataSuccess = (payload) => ({
	type: actions.USER_DATA_SUCCESS,
	payload,
});

export const userDataError = (payload) => ({
	type: actions.USER_DATA_ERROR,
	payload,
});

//login
export const login = (payload) => ({
	type: actions.LOGIN,
	payload,
});

export const loginSuccess = (payload) => ({
	type: actions.LOGIN_SUCCESS,
	payload,
});

export const loginError = (payload) => ({
	type: actions.LOGIN_ERROR,
	payload,
});

//Registrar Usuario
export const registrarUsuario = (payload) => ({
	type: actions.REGISTRAR_USUARIO,
	payload,
});

export const registrarUsuarioNuevo = (data) => ({
	type: actions.REGISTRAR_USUARIO_NUEVO,
	data,
});

export const registrarUsuarioNuevoSuccess = (payload) => ({
	type: actions.REGISTRAR_USUARIO_NUEVO_SUCCESS,
	payload,
});

export const registrarUsuarioNuevoError = (payload) => ({
	type: actions.REGISTRAR_USUARIO_NUEVO_ERROR,
	payload,
});

export const registrarUsuarioSuccess = (payload) => ({
	type: actions.REGISTRAR_USUARIO_SUCCESS,
	payload,
});

export const registrarUsuarioError = (payload) => ({
	type: actions.REGISTRAR_USUARIO_ERROR,
	payload,
});

export const getUsers = (payload) => ({
	type: actions.GET_USERS,
	payload,
});

export const getUsersSuccess = (payload) => ({
	type: actions.GET_USERS_SUCCESS,
	payload,
});

export const clearUsuario = () => ({
	type: actions.CLEAR_USUARIO,
});

export const getUsersError = (payload) => ({
	type: actions.GET_USERS_ERROR,
	payload,
});



export const removeControl = (id) => ({
	type: actions.REMOVE_CONTROL,
	id,
});



export const removeControlSuccess = (payload) => ({
	type: actions.REMOVE_CONTROL_SUCCESS,
	payload,
});

export const removeControlError = (payload) => ({
	type: actions.REMOVE_CONTROL_ERROR,
	payload,
});



export const deleteUser = (id) => ({
	type: actions.DELETE_USER,
	id,
});



export const deleteUserSuccess = (payload) => ({
	type: actions.DELETE_USER_SUCCESS,
	payload,
});

export const deleteUserError = (payload) => ({
	type: actions.DELETE_USER_ERROR,
	payload,
});

export const getRecetas = (payload) => ({
	type: actions.GET_RECETAS,
	payload,
});

export const getRecetasSuccess = (payload) => ({
	type: actions.GET_RECETAS_SUCCESS,
	payload,
});

export const getRecetasError = (payload) => ({
	type: actions.GET_RECETAS_ERROR,
	payload,
});

export const getPlanes = (payload) => ({
	type: actions.GET_PLANES,
	payload,
});

export const getPlanesSuccess = (payload) => ({
	type: actions.GET_PLANES_SUCCESS,
	payload,
});

export const getPlanesError = (payload) => ({
	type: actions.GET_PLANES_ERROR,
	payload,
});


export const newPlan = (payload) => ({
	type: actions.NEW_PLAN,
	payload,
});

export const newPlanSuccess = (payload) => ({
	type: actions.NEW_PLAN_SUCCESS,
	payload,
});

export const newPlanError = (payload) => ({
	type: actions.NEW_PLAN_ERROR,
	payload,
});

export const getPlanById = (id) => ({
	type: actions.GET_PLAN_BY_ID,
	id,
});

export const getPlanByIdSuccess = (data) => ({
	type: actions.GET_PLAN_BY_ID_SUCCESS,
	data,
});

export const getPlanByIdError = (data) => ({
	type: actions.GET_PLAN_BY_ID_ERROR,
	data,
});

export const updateEditedPlan = (data, id) => ({
	type: actions.UPDATE_PLAN,
	data,
	id,
});

export const updateEditedPlanSuccess = (data) => ({
	type: actions.UPDATE_PLAN_SUCCESS,
	data,
});

export const updateEditedPlanError = (data) => ({
	type: actions.UPDATE_PLAN_ERROR,
	data,
});



export const getAgenda = (payload) => ({
	type: actions.GET_AGENDA,
	payload,
});

export const getAgendaSuccess = (payload) => ({
	type: actions.GET_AGENDA_SUCCESS,
	payload,
});

export const getAgendaError = (payload) => ({
	type: actions.GET_AGENDA_ERROR,
	payload,
});

export const gotoEditProfile = (payload) => ({
	type: actions.GO_EDIT_PROFILE,
	payload,
});


export const getControllsByUserId =(payload) => ({
	type: actions.GET_CONTROLLS_BY_USER_ID,
	payload,
});

export const getControllsByUserIdSuccess = (payload) => ({
	type: actions.GET_CONTROLLS_BY_USER_ID_SUCCESS,
	payload,
});

export const getControllsByUserIdError = (payload) => ({
	type: actions.GET_CONTROLLS_BY_USER_ID_ERROR,
	payload,
});


export const updateEditedControl = (data, id) => ({
	type: actions.UPDATE_CONTROLL,
	data,
	id,
});

export const updateEditedControlSuccess = (payload) => ({
	type: actions.UPDATE_CONTROLL_SUCCESS,
	payload,
});

export const updateEditedControlError = (payload) => ({
	type: actions.UPDATE_CONTROLL_ERROR,
	payload,
});


export const updateEditedCalendar = (data, id) => ({
	type: actions.UPDATE_CALENDAR,
	data,
	id,
});

export const updateEditedCalendarSuccess = (payload) => ({
	type: actions.UPDATE_CALENDAR_SUCCESS,
	payload,
});

export const updateEditedCalendarError = (payload) => ({
	type: actions.UPDATE_CALENDAR_ERROR,
	payload,
});



export const newControl =(payload) => ({
	type: actions.NEW_CONTROLL,
	payload,
});

export const newControlSuccess = (payload) => ({
	type: actions.NEW_CONTROLL_SUCCESS,
	payload,
});

export const newControlError = (payload) => ({
	type: actions.NEW_CONTROLL_ERROR,
	payload,
});


export const AssignPlan =(payload) => ({
	type: actions.ASSIGN_PLAN,
	payload,
});

export const AssignPlanSuccess = (payload) => ({
	type: actions.ASSIGN_PLAN_SUCCESS,
	payload,
});

export const AssignPlanError = (payload) => ({
	type: actions.ASSIGN_PLAN_ERROR,
	payload,
});


export const newCalendar =(payload) => ({
	type: actions.NEW_CALENDAR,
	payload,
});

export const newCalendarSuccess = (payload) => ({
	type: actions.NEW_CALENDAR_SUCCESS,
	payload,
});

export const newCalendarError = (payload) => ({
	type: actions.NEW_CALENDAR_ERROR,
	payload,
});





export const getControlById = (id) => ({
	type: actions.GET_CONTROLL_BY_ID,
	id,
});

export const getControlByIdSuccess = (data) => ({
	type: actions.GET_CONTROLL_BY_ID_SUCCESS,
	data,
});

export const getControlByIdError = (data) => ({
	type: actions.GET_CONTROLL_BY_ID_ERROR,
	data,
});


export const gotoControles = (payload) => ({
	type: actions.GO_CONTROLES,
	payload,
});

export const gotoControlesSuccess = (payload) => ({
	type: actions.GO_CONTROLES_SUCCESS,
	payload,
});

export const gotoControlesError = (payload) => ({
	type: actions.GO_CONTROLES_ERROR,
	payload,
});

export const imageNames = (x) => ({
	type: actions.IMAGE_NAME,
	x,
});

export const imageNamesSuccess = (x) => ({
	type: actions.IMAGE_NAME_SUCCESS,
	x,
});

export const imageNamesError = (x) => ({
	type: actions.IMAGE_NAME_ERROR,
	x,
});

export const deletePlan = (id) => ({
	type: actions.DELETE_PLAN,
	id,
});

export const deletePlanSuccess = (payload) => ({
	type: actions.DELETE_PLAN_SUCCESS,
	payload,
});

export const deletePlanError = (payload) => ({
	type: actions.DELETE_PLAN_ERROR,
	payload,
});


export const deleteReceta = (id) => ({
	type: actions.DELETE_RECETA,
	id,
});

export const deleteRecetaSuccess = (payload) => ({
	type: actions.DELETE_RECETA_SUCCESS,
	payload,
});

export const deleteRecetaError = (payload) => ({
	type: actions.DELETE_RECETA_ERROR,
	payload,
});


export const deleteControl = (id) => ({
	type: actions.DELETE_CONTROL,
	id,
});

export const deleteControlSuccess = (payload) => ({
	type: actions.DELETE_CONTROL_SUCCESS,
	payload,
});

export const deleteControlError = (payload) => ({
	type: actions.DELETE_CONTROL_ERROR,
	payload,
});

export const newReceta = (payload) => ({
	type: actions.NEW_RECETA,
	payload,
});

export const newRecetaSuccess = (payload) => ({
	type: actions.NEW_RECETA_SUCCESS,
	payload,
});

export const newRecetaError = (payload) => ({
	type: actions.NEW_RECETA_ERROR,
	payload,
});

export const getRecetaById = (id) => ({
	type: actions.GET_RECETA_BY_ID,
	id,
});

export const getRecetaByIdSuccess = (data) => ({
	type: actions.GET_RECETA_BY_ID_SUCCESS,
	data,
});

export const getRecetaByIdError = (data) => ({
	type: actions.GET_RECETA_BY_ID_ERROR,
	data,
});

export const updateEditedReceta = (data, id) => ({
	type: actions.UPDATE_RECETA,
	data,
	id,
});

export const updateEditedRecetaSuccess = (data) => ({
	type: actions.UPDATE_RECETA_SUCCESS,
	data,
});

export const updateEditedRecetaError = (data) => ({
	type: actions.UPDATE_RECETA_ERROR,
	data,
});



export const getHomeSlider = (payload) => ({
	type: actions.GET_HOME_SLIDER,
	payload,
});

export const getHomeSliderSuccess = (payload) => ({
	type: actions.GET_HOME_SLIDER_SUCCESS,
	payload,
});

export const getHomeSliderError = (payload) => ({
	type: actions.GET_HOME_SLIDER_ERROR,
	payload,
});


export const getHomeSliderMobile = () => ({
	type: actions.GET_HOME_SLIDER_MOBILE,
});

export const getHomeSliderMobileSuccess = (payload) => ({
	type: actions.GET_HOME_SLIDER_MOBILE_SUCCESS,
	payload,
});

export const getHomeSliderMobileError = (payload) => ({
	type: actions.GET_HOME_SLIDER_MOBILE_ERROR,
	payload,
});



export const getTips = (payload) => ({
	type: actions.GET_TIPS,
	payload,
});

export const getTipsSuccess = (payload) => ({
	type: actions.GET_TIPS_SUCCESS,
	payload,
});

export const getTipsError = (payload) => ({
	type: actions.GET_TIPS_ERROR,
	payload,
});



export const newTip = (payload) => ({
	type: actions.NEW_TIP,
	payload,
});

export const newTipSuccess = (payload) => ({
	type: actions.NEW_TIP_SUCCESS,
	payload,
});

export const newTipError = (payload) => ({
	type: actions.NEW_TIP_ERROR,
	payload,
});


export const newHomeSlider = (payload) => ({
	type: actions.NEW_HOME_SLIDER,
	payload,
});

export const newHomeSliderSuccess = (payload) => ({
	type: actions.NEW_HOME_SLIDER_SUCCESS,
	payload,
});

export const newHomeSliderError = (payload) => ({
	type: actions.NEW_HOME_SLIDER_ERROR,
	payload,
});

export const newHomeSliderMobile = (payload) => ({
	type: actions.NEW_HOME_MOBILE_SLIDER,
	payload,
});

export const newHomeSliderMobileSuccess = (payload) => ({
	type: actions.NEW_HOME_MOBILE_SLIDER_SUCCESS,
	payload,
});

export const newHomeSliderMobileError = (payload) => ({
	type: actions.NEW_HOME_MOBILE_SLIDER_ERROR,
	payload,
});


export const updateEditedTip = (data, id) => ({
	type: actions.UPDATE_TIP,
	data,
	id,
});

export const updateEditedTipSuccess = (data) => ({
	type: actions.UPDATE_TIP_SUCCESS,
	data,
});

export const updateEditedTipError = (data) => ({
	type: actions.UPDATE_TIP_ERROR,
	data,
});

export const updateEditedHomeSlider = (data, id) => ({
	type: actions.UPDATE_HOME_SLIDER,
	data,
	id,
});

export const updateEditedHomeSliderSuccess = (data) => ({
	type: actions.UPDATE_HOME_SLIDER_SUCCESS,
	data,
});

export const updateEditedHomeSliderError = (data) => ({
	type: actions.UPDATE_HOME_SLIDER_ERROR,
	data,
});

export const updateEditedHomeSliderMobile = (data, id) => ({
	type: actions.UPDATE_HOME_MOBILE_SLIDER,
	data,
	id,
});

export const updateEditedHomeSliderMobileSuccess = (data) => ({
	type: actions.UPDATE_HOME_SLIDER_MOBILE_SUCCESS,
	data,
});

export const updateEditedHomeSliderMobileError = (data) => ({
	type: actions.UPDATE_HOME_SLIDER_MOBILE_ERROR,
	data,
});


export const deleteTip = (id) => ({
	type: actions.DELETE_TIP,
	id,
});

export const deleteTipSuccess = (payload) => ({
	type: actions.DELETE_TIP_SUCCESS,
	payload,
});

export const deleteTipError = (payload) => ({
	type: actions.DELETE_TIP_ERROR,
	payload,
});

export const getTipById = (id) => ({
	type: actions.GET_TIP_BY_ID,
	id,
});

export const getTipByIdSuccess = (data) => ({
	type: actions.GET_TIP_BY_ID_SUCCESS,
	data,
});

export const getTipByIdError = (data) => ({
	type: actions.GET_TIP_BY_ID_ERROR,
	data,
});


export const updateEditedFile = (data, id) => ({
	type: actions.UPDATE_FILE,
	data,
	id,
});

export const updateEditedFileSuccess = (data) => ({
	type: actions.UPDATE_FILE_SUCCESS,
	data,
});

export const updateEditedFileError = (data) => ({
	type: actions.UPDATE_FILE_ERROR,
	data,
});


export const newFile = (payload) => ({
	type: actions.NEW_FILE,
	payload,
});

export const newFileSuccess = (payload) => ({
	type: actions.NEW_FILE_SUCCESS,
	payload,
});

export const newFileError = (payload) => ({
	type: actions.NEW_FILE_ERROR,
	payload,
});


export const getFileById = (id) => ({
	type: actions.GET_FILE_BY_ID,
	id,
});

export const getFileByIdSuccess = (data) => ({
	type: actions.GET_FILE_BY_ID_SUCCESS,
	data,
});

export const getFileByIdError = (data) => ({
	type: actions.GET_FILE_BY_ID_ERROR,
	data,
});

export const mercadoPagoBuy =(payload) => ({
	type: actions.NEW_MERCADO_PAGO_SELL,
	payload,
});

export const mercadoPagoBuySuccess = (payload) => ({
	type: actions.NEW_MERCADO_PAGO_SELL_SUCCESS,
	payload,
});

export const mercadoPagoBuyError = (payload) => ({
	type: actions.NEW_MERCADO_PAGO_SELL_ERROR,
	payload,
});


export const getComprasByUserId = (id) => ({
	type: actions.GET_COMPRAS_BY_USER_ID,
	id,
});

export const getComprasByUserIdSuccess = (data) => ({
	type: actions.GET_COMPRAS_BY_USER_ID_SUCCESS,
	data,
});

export const getComprasByUserIdError = (data) => ({
	type: actions.GET_COMPRAS_BY_USER_ID_ERROR,
	data,
});

export const getVentas = () => ({
	type: actions.GET_VENTAS,
});

export const getVentasSuccess = (data) => ({
	type: actions.GET_VENTAS_SUCCESS,
	data,
});

export const getVentasError = (data) => ({
	type: actions.GET_VENTAS_ERROR,
	data,
});


export const forgotPassword = (payload) => ({
	type: actions.FORGOT_PASSWORD,
	payload,
});

export const forgotPasswordSuccess = (payload) => ({
	type: actions.FORGOT_PASSWORD_SUCCESS,
	payload,
});

export const forgotPasswordError = (payload) => ({
	type: actions.FORGOT_PASSWORD_ERROR,
	payload,
});

export const newPassword = (payload) => ({
	type: actions.NEW_PASSWORD,
	payload,
});

export const newPasswordSuccess = (payload) => ({
	type: actions.NEW_PASSWORD_SUCCESS,
	payload,
});

export const newPasswordError = (payload) => ({
	type: actions.NEW_PASSWORD_ERROR,
	payload,
});


