import React from 'react';
import { connect } from 'react-redux';
import { getUserById, clearUsuario } from 'actions';
import _ from 'lodash';
import Loader from 'assets/img/loadern.gif';
import { goBack } from 'react-router-redux';
import { updateEditedTip, getTipById, newTip } from 'actions';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import './styles.css';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

// reactstrap components
import { Button, Card, CardBody, CardFooter, Form, Input, Container, Row, Col, Label } from 'reactstrap';

class FichaTip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tip: {
				title: '',
				description: '',
				editorState: {},
				type: '',
				file: '',
				items: '',
				avatar: '',
				nota: '',
				video: '',
				videoPromo: '',
				premium: false,
				createdAt: '',
			},
			tipData: [],
			imagenes: '',
			msg: '',
			editMode: false,
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-tip-component');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.tip !== state.tip) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.tip !== this.props.tip) {
			this.setState({
				tip: this.props.tip,
				tipData: this.props.tip,
				editMode: true,
				selectedFiles: null,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getTipById(id);
			return;
		}

		this.setState({
			selectedFiles: null,
		});
	}



	//ES PARA IMAGEN
	onDrop = (acceptedFiles) => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}
		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'images') {
			const images = this.state.tip.avatar !== '' ? _.split(this.state.tip.avatar, ',') : [];
			const imagenesFiltered = _.map(images, (m) => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			this.removeFile(x); // this in case you prefer upload before
			const nuevaTip = { ...this.state.tip, avatar: filtered };
			this.setState({ ...this.state, tip: nuevaTip });
		} else {
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, (s) => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};


	handleInputEditorState = (editorState) => {
		const { tip } = this.state;
		tip.editorState = editorState;
		this.setState({ tip });
	};

	removeFile = (fileName) => {
		if (fileName) {
			axios
				.post(
					'https://premium-wraper-back.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch((error) => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = (files) => {
		this.setState({
			selectedFiles: files,
		});
	};

	showtheImages = () => {
		const { selectedFiles } = this.state;

		const images = this.state.tip.avatar !== '' ? _.split(this.state.tip.avatar, ',') : [];
		let oldImages = null;
		oldImages = _.map(images, (x) => {
			if (x !== '' && x !== 'undefined') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={(e) => {
									this.removeImageHandler(e, x, 'images');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/' + x}
						></img>
					</div>
				);
				return img;
			} else return null;
		});

		const returnImages =
			selectedFiles &&
			_.map(selectedFiles, (x) => {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						<img alt="..." src={Loader} width={'70px'} />
						<h6>CARGANDO IMAGENES...</h6>
					</div>
				);
				return img;
			});

		return (
			<div>
				{oldImages}
				{returnImages}
			</div>
		);
	};


	handlerSelect = (value, type) => {
		const toChange = value.value;
		const { tip } = this.state;
		tip[type] = toChange;
		this.setState({ ...this.state, tip });
	};

	handleInput = (event) => {
		const { tip } = this.state;
		tip[event.target.name] = event.target.value;
		this.setState({ tip });
	};

	handleCheck = (event) => {
		const { tip } = this.state;
		tip[event.target.name] = !tip[event.target.name];
		this.setState({ tip });
	};


	handlerSend = () => {
		const data = new FormData();
        const idTip = this.props.tip ? this.props.tip._id : null;

		const { tip, selectedFiles } = this.state;

		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}

		tip.title && data.append('title', tip.title);
		tip.description && data.append('description', tip.description);
		tip.editorState && data.append('editorState', tip.editorState);
		tip.type && data.append('type', tip.type);
		tip.file && data.append('file', tip.file);
		tip.items && data.append('items', tip.items);
		tip.avatar && data.append('avatar', tip.avatar);
		tip.nota && data.append('nota', tip.nota);
		tip.video && data.append('video', tip.video);
		tip.videoPromo && data.append('videoPromo', tip.videoPromo);

		if (idTip) {
			this.props.updateEditedTip(data, idTip);
		} else {
			this.props.newTip(data);
		}
	};

	componentUploadImage = () => {
		const maxSize = 3000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed #dadada',
										marginBottom: '30px',
										textAlign: 'center',
										color: '#dadada',
									}}
								>
									{this.props.loading ? (
										<img alt="..." style={{ marginTop: '30px' }} src={Loader} width={'70px'} />
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="icon-line2-picture"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Imagen demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h6>Imagenes Tip</h6>
				</div>
				<div>{this.state.tip ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};





	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b></b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handlerGoBack() {
		this.props.goBack();
	}

	render() {
		const { tip } = this.state;

		return this.props.loading || !tip ? (
			<div style={{ width: '100%', textAlign: 'center', color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
		) : (
			<>
				<Container>
					<Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="success"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: '#dadada' }}>
								   {this.state.editMode ? 'Editar Tip' : "Crear nuevo Tip"}
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md="9">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Row>
											<Col className="mr-auto ml-auto" md="12" style={{ padding: '20px' }}>
												{this.componentUploadImage()}
											</Col>
										</Row>
										<Form className="form">
											<Row md="12">
												<Col className="mr-auto ml-auto" md="12">
													<label>Titulo</label>
													<Input
														placeholder=""
														name="title"
														type="text"
														value={tip.title || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>

<label> Detalles </label>
													<SunEditor
														lang="es"
														onChange={this.handleInputEditorState}
														setContents={tip.editorState}
														setOptions={{
															height: 400,
															buttonList: [
																[
																	'fontColor',
																	'hiliteColor',
																	'bold',
																	'underline',
																	'strike',
																	'subscript',
																	'superscript',
																	'align',
																	'font',
																	'fontSize',
																	'formatBlock',
																	'hiliteColor',
																	'horizontalRule',
																	'lineHeight',
																	'list',
																	'paragraphStyle',
																	'table',
																	'template',
																	'textStyle',
																],
															],
															colorList: [
																[
																	'#ccc',
																	'#dedede',
																	'#000',
																	'OrangeRed',
																	'Orange',
																	'RoyalBlue',
																	'SaddleBrown',
																],
															], // Or Array of button list, eg. [['font', 'align'], ['image']]
															// Other option
														}}
														placeholder="Detalles del tip..."
														enableToolbar={true}
														style={{ backgroundColor: '#000' }}
													/>
													<br />
													<br />


                                                    <label>Tipo de Tip</label>
													<Input
														placeholder=""
														name="type"
														type="text"
														value={tip.type || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
													
													<label>Video</label>
													<Input
														placeholder="Link del video"
														name="video"
														type="text"
														value={tip.video || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>

													<label>Video Promoción</label>
													<Input
														placeholder="Link del video"
														name="videoPromo"
														type="text"
														value={tip.videoPromo || ''}
														onChange={this.handleInput}
														style={{ height: '45px' }}
													/>
												</Col>
											</Row>
										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="success"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Guardar Tip
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserById: (id) => dispatch(getUserById(id)),
		clearUsuario: () => dispatch(clearUsuario()),
		newTip: (data) => dispatch(newTip(data)),
		updateEditedTip: (data, id) => dispatch(updateEditedTip(data, id)),
		getTipById: (id) => dispatch(getTipById(id)),
		goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FichaTip);